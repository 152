import React from 'react'
import { Table } from 'antd';

const columns = [
  {
    title: 'name',
    dataIndex: 'fullname',
    render(text) {
      return {
        props: {
          style: { fontSize: "16px", textAlign: "center", color: '#483a15' }
        },
        children: <div style={{ fontWeight: '600' }}  >vishwa</div>
      }
    }
  },
  {
    title: 'email',
    dataIndex: 'emailid',
    render(text) {
      return {
        props: {
          style: { fontSize: "16px", textAlign: "center", color: '#483a15' }
        },
        children: <div style={{ fontWeight: '600' }}  >shahvishwa3264@gmail.com</div>
      }
    }
  },
  {
    title: 'mobile no.',
    dataIndex: 'contactnumber',
    render(text) {
      return {
        props: {
          style: { fontSize: "16px", textAlign: "center", color: '#483a15' }
        },
        children: <div style={{ fontWeight: '600' }}  >6355553146</div>
      }
    }
  },
  {
    title: 'subject',
    dataIndex: 'subjecttocontact',
    render(text) {
      return {
        props: {
          style: { fontSize: "16px", textAlign: "center", color: '#483a15' }
        },
        children: <div style={{ fontWeight: '600' }}  >for inquiry of order</div>
      }
    }
  },
  {
    title: 'time',
    dataIndex: 'contactformtime',
    render(text) {
      return {
        props: {
          style: { fontSize: "16px", textAlign: "center", color: '#483a15' }
        },
        children: <div style={{ fontWeight: '600' }}  >12:00pm</div>
      }
    }
  },
]


function Contactdetails() {
  return (
    <div>
      <div className='col d-flex justify-content-end align-items-center'>
        <button type="button" class="btn allproducts_addnew_button mb-3 me-4">Export</button>
      </div>

      <Table columns={columns} dataSource={columns} />
    </div>
  )
}

export default Contactdetails