import { useState } from 'react'
import './Allproducts.css'
import { Button, Table } from 'antd';

import { StarOutlined, StarFilled, StarTwoTone } from '@ant-design/icons';
// import React, { useState } from 'react';
function Allproducts() {
    const [selectcategory, setSelectategory] = useState([
        {
            dropdownname: "Select a category"
        },
        {
            dropdownname: "Moboil & Accessory"
        },
        {
            dropdownname: "Laptop"
        },
        {
            dropdownname: ""
        },
    ])
    const [filterbyproduct, setFilterbyproduct] = useState([
        {
            dropdownname: "Filter by product type"
        },
        {
            dropdownname: "Simple Product"
        },
        {
            dropdownname: "Downloadable"
        },
        {
            dropdownname: "Virtual"
        },
        {
            dropdownname: "Grouped Products"
        },
        {
            dropdownname: "External/Affiliate Product"
        },
        {
            dropdownname: "variable Products"
        }
    ])
    const [filterbystockstatus, setFilterbystockstatus] = useState([
        {
            dropdownname: "Filter by stock status"
        },
        {
            dropdownname: "In Stock"
        },
        {
            dropdownname: "Out Of Stock"
        },
    ]);
    const [selectedOption, setSelectedOption] = useState(filterbyproduct[0].dropdownname);

    const columns = [
        {
            title: 'Image',
            dataIndex: 'Image',
        },
        {
            title: 'Name',
            dataIndex: 'Name',
        },
        {
            title: 'SKU',
            dataIndex: 'SKU',
        },
        {
            title: 'Stock',
            dataIndex: 'Stock',
        },
        {
            title: 'Price',
            dataIndex: 'Price',
        },
        {
            title: 'Categories',
            dataIndex: 'Categories',
        },
        {
            title: 'Tag',
            dataIndex: 'Tag',
        },
        {
            title: 'Fav',
            dataIndex: 'Fav',
        },
        {
            title: 'Date',
            dataIndex: 'Date',
        },
    ];
    const data = [];

    for (let i = 0; i < 46; i++) {
        data.push({
            key: i,
            Image: `Edward King ${i}`,
            Name: `Product Name`,
            SKU: 12345,
            Stock: `In Stock`,
            Price: `₹29,999`,
            Categories: `Categoies Name`,
            Tag: `-`,
            Fav: 1 ,
            Date: `Publish Date`

        });
    }
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading, setLoading] = useState(false);

    // const start = () => {
    //   setLoading(true); // ajax request after empty completing

    //   setTimeout(() => {
    //     setSelectedRowKeys([]);
    //     setLoading(false);
    //   }, 1000);
    // };

    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    return (
        <div className='px-5'>
            <div className='container-fuild'>
                <div className='row'>
                    <div className='col d-flex'>
                        <h2 className='allproducts_title'>Products</h2>
                        <button type="button" class="btn allproducts_addnew_button">Add New</button>
                        <button type="button" class="btn allproducts_addnew_button">Import</button>
                        <button type="button" class="btn allproducts_addnew_button">Export</button>
                    </div>

                    <div className='col d-flex justify-content-end'>
                        <input class="form-control allproducts_search_feild me-2" type="search" placeholder="Search" aria-label="Search" />
                        <button class="btn allproducts_search_button" type="submit">Search Products</button>
                    </div>
                </div>
            </div>
            <div className='d-flex my-4'>
                <div class="dropdown">
                    <button class="btn allproducts_bulkaction_dropdown dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Bulk Action
                    </button>
                    <ul class="dropdown-menu">
                        <li><a class="dropdown-item allproducts_bulkaction_dropdown_menu" href="#">Bulk Action</a></li>
                        <li><a class="dropdown-item allproducts_bulkaction_dropdown_menu" href="#">Edit</a></li>
                        <li><a class="dropdown-item allproducts_bulkaction_dropdown_menu" href="#">Move To Trash</a></li>
                    </ul>
                </div>
                <button type="button" class="btn btn-outline-dark allproducts_bulkaction_apply_button">Apply</button>
                <div class="dropdown">
                    <button class="btn allproducts_select_category dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Select a category
                    </button>
                    <ul class="dropdown-menu">
                        {
                            selectcategory.map((item) => {
                                return (
                                    <li><a class="dropdown-item allproducts_bulkaction_dropdown_menu">{item.dropdownname}</a></li>
                                )
                            }
                            )
                        }
                    </ul>
                </div>
                <select class="form-select allproducts_filterbyproduct_dropdown" aria-label="Default select example">
                    <option selected>Filter by product type</option>
                    {
                        filterbyproduct.map((item) => {
                            return (
                                <option className='allproducts_filterbyproduct_dropdown_menu' value="1">{item.dropdownname}</option>
                            )
                        }
                        )
                    }

                </select>
                <div class="dropdown">
                    <button class="btn allproducts_filterbyproduct_dropdown dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        {selectedOption}
                    </button>
                    <ul class="dropdown-menu">
                        {
                            filterbyproduct.map((item) => {
                                return (
                                    <li><a className={selectedOption === item.dropdownname ? "bgColorRed dropdown-item allproducts_filterbyproduct_dropdown_menu" : "dropdown-item allproducts_filterbyproduct_dropdown_menu"} onClick={() => setSelectedOption(item.dropdownname)}>{item.dropdownname}</a></li>
                                )
                            }
                            )
                        }

                    </ul>
                </div>
                <div class="dropdown">
                    <button class="btn allproducts_bulkaction_dropdown dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Filter by stock status
                    </button>
                    <ul class="dropdown-menu">
                        {
                            filterbystockstatus.map((item) => {
                                return (
                                    <li><a class="dropdown-item allproducts_filterbystockstatus_dropdown_menu">{item.dropdownname}</a></li>
                                )
                            }
                            )
                        }
                    </ul>
                </div>
            </div>
            <div className="col allproducts_apds_division">
                <p className='active_allproducts_apds '>All<span>(26)</span></p>
                <p className='allproducts_apds'>Published<span>(25)</span></p>
                <p className='allproducts_apds'>Draft<span>(1)</span></p>
                <p className='allproducts_apds'>sorting<span>(25)</span></p>
            </div>
            <div
                style={{
                    marginBottom: 16,
                }}>
                {/* <Button type="primary" onClick={start} disabled={!hasSelected} loading={loading}>
                      Reload
                       </Button> */}
                <span className='all_products_data_table'
                    style={{
                        marginLeft: 8,
                    }}
                >
                    {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
                </span>
            </div>
            <div className='w-100'>
            <Table className='all_products_tables' rowSelection={rowSelection} columns={columns} dataSource={data} />
            </div>
        </div>
    )
}
export default Allproducts;