export const initialState = {
    basket:[],
    ShowLoginPop:true,
    CloseLogin:false,
    ShowAdminLoginPop:false,
    ComparePop:false,
    CompareVar:false,
    NewNotification:false,

};

const reducer = (state,action)  =>
{

    switch(action.type)
    {case 'ADD_TO_BASKET':
    return{
        ...state,basket:[...state.basket,action.item],
    };
    case 'Login':
    return{
        ...state,ShowLoginPop:true,
    };
    case 'Logout':
        return{
            ...state,ShowLoginPop:false,
        };
    case 'CloseLogin':
        return{
            ...state,CloseLogin:false,
        };
    case 'OpenLogin':
        return{
            ...state,CloseLogin:true,
        };
    case 'CloseALogin':
        return{
            ...state,ShowAdminLoginPop:true,
        };
    case 'OpenALogin':
        return{
            ...state,ShowAdminLoginPop:false,
        };
    case 'OpenComparePop':
        return{
            ...state,ComparePop:true,
        };
    case 'CloseComparePop':
        return{
            ...state,ComparePop:false,
        };
        
    case 'UpdateCompare':
        return{
            ...state,CompareVar:!state.CompareVar,
        };
    case 'NewNotification':
        return{
            ...state,NewNotification:true,
        }

    // case 'CLEAR_BASKET':
    // return{
    //     ...state,basket:[],
    // };
    default: return state;
    }
}
export default reducer;