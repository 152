import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useStateValue } from "../../../StateProviders";

function Edituser() {
  const [_, dispatch] = useStateValue();
  let navigate = useNavigate();
  const params = useParams();
  const { UID } = params;

  const [userData, setUserData] = useState({});
  const [editable, setEditable] = useState(false);
  const [error, setError] = useState({
    Phone_Num: "",
  });

  const getUserData = () => {
    var Url = window.API_PREFIX + "admin/profile";
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("Token"),
        UserId: UID,
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "1") {
          setUserData(data["UserDetail"]);
        } else {
          dispatch({ type: "OpenALogin" });
        }
      });
  };

  useEffect(() => {
    getUserData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "Phone_Num" && value === "") {
      return;
    }
    setError({
      ...error,
      [name]: "",
    });
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (userData.Phone_Num.length !== 10) {
      setError({
        ...error,
        Phone_Num: "Enter valid phone number",
      });
      return;
    }
    var Url = window.API_PREFIX + "admin/edit_profile";
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("Token"),
        ...userData,
        UserId: UID,
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data["status"] === "1") {
          navigate("/alluser");
        } else {
          dispatch({ type: "OpenALogin" });
        }
      });
  };

  return (
    <div style={{ padding: "10px" }}>
      <div style={{ display: "flex", gap: "15px" }}>
        <button
          className="allusercart_back_btn"
          onClick={() => {
            navigate("/alluser");
          }}
        >
          Back
        </button>
        <button
          className="allusercart_back_btn"
          onClick={() => {
            setEditable(!editable);
          }}
        >
          Edit
        </button>
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <label>User Active</label>
          <select
            class="form-control allusers_search_feild"
            name="Is_active"
            value={userData.Is_active}
            onChange={handleChange}
            disabled={!editable}
          >
            <option value="true">On</option>
            <option value="false">Off</option>
          </select>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="mt-3">
          <div className="d-flex">
            <div className="mt-3">
              <label>Title</label>
              <select
                class="form-control allusers_search_feild mt-2 "
                name="Title"
                placeholder="Title"
                value={userData.Title}
                onChange={handleChange}
                disabled={!editable}
              >
                <option value="">Select</option>
                <option value="Mr">Mr</option>
                <option value="Mrs">Mrs</option>
                <option value="Miss">Miss</option>
                <option value="Ms">Ms</option>
                <option value="Dr">Dr</option>
                <option value="Prof">Prof</option>
              </select>
            </div>
            <div className="mt-3 mx-2">
              <label>First Name</label>
              <input
                class="form-control allusers_search_feild  mt-2"
                type="text"
                name="FirstName"
                placeholder="First Name"
                value={userData.FirstName}
                onChange={handleChange}
                disabled={!editable}
              />
            </div>
          </div>

          <div className="d-flex">
            <div className="mt-3">
              <label>Middle Name</label>
              <input
                class="form-control allusers_search_feild  mt-2"
                type="text"
                name="MiddleName"
                placeholder="Middle Name"
                value={userData.MiddleName}
                onChange={handleChange}
                disabled={!editable}
              />
            </div>
            <div className="mt-3 mx-2">
              <label>Last Name</label>
              <input
                class="form-control allusers_search_feild  mt-2"
                type="text"
                name="LastName"
                placeholder="Last Name"
                value={userData.LastName}
                onChange={handleChange}
                disabled={!editable}
              />
            </div>
          </div>

          <div className="d-flex">
            <div className="mt-3">
              <label>Date of Anniversary</label>
              <input
                class="form-control allusers_search_feild mt-2 "
                type="date"
                name="DateOfAnniversary"
                value={userData.DateOfAnniversary}
                onChange={handleChange}
                disabled={!editable}
              />
            </div>
            <div className="mt-3 mx-2">
              <label>Email</label>
              <input
                class="form-control allusers_search_feild mt-2 "
                type="Email"
                name="Email"
                placeholder="Email"
                value={userData.Email}
                onChange={handleChange}
                disabled={!editable}
                required
              />
            </div>
          </div>
          <div className="d-flex">
            <div className="mt-3">
              <label>Phone Number</label>
              <input
                class="form-control allusers_search_feild mt-2 "
                type="number"
                name="Phone_Num"
                placeholder="Phone Number"
                value={userData.Phone_Num}
                onChange={handleChange}
                disabled={!editable}
                required
                maxLength={10}
              />
              {error.Phone_Num !== "" && (
                <label style={{ color: "red" }}>{error.Phone_Num}</label>
              )}
            </div>
            <div className="mt-3 mx-2">
              <label>Date of Birth</label>
              <input
                class="form-control allusers_search_feild mt-2 "
                type="date"
                name="DOB"
                value={userData.DOB}
                onChange={handleChange}
                disabled={!editable}
              />
            </div>
          </div>
          <div className="mt-3">
            <label>Gender</label>
            <select
              class="form-control allusers_search_feild mt-2 "
              name="Gender"
              placeholder="Gender"
              value={userData.Gender}
              onChange={handleChange}
              disabled={!editable}
            >
              <option value="">Select</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <button
            type="submit"
            className="allusercart_back_btn mt-3"
            disabled={!editable}
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
}

export default Edituser;
