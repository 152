import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate } from 'react-router-dom';
import './Sidebar.css';
import { useStateValue } from "../../StateProviders";
import Adminheader from '../AdminComponent/Adminheader/Adminheader';
import Tooltip from '@mui/material/Tooltip';

const drawerWidth = 300;

const openedMixin = (theme) => ({
    backgroundColor: '#8f7d4f',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#8f7d4f',
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#8f7d4f',
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        backgroundColor: '#8f7d4f',
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);



export default function Sidebartwo() {

    const [{ ShowAdminLoginPop }, dispatch] = useStateValue();

    let navigate = useNavigate();

    const [oo, setoo] = React.useState(false);
    const [ooo, setooo] = React.useState(false);
    const [oooo, setoooo] = React.useState(false);


    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ display: 'flex', }}>
            <AppBar position="fixed" open={open}>
                <Toolbar
                >
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>


                    <Adminheader />

                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ color: 'white', fontSize: '20px' }}>kandariwala Kaka</div>
                    <IconButton onClick={handleDrawerClose} style={{ color: 'white' }}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />

                <List>

                    <ListItem disablePadding sx={{ display: 'block' }}
                        className={window.location.pathname.includes('/dashboard') ? "new_sidebar_list_name_active" : ""}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                                position: 'relative'
                            }}

                        >
                            <Tooltip title="Dashboard" placement="right">
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                        position: 'absolute',
                                        top: '33px'
                                    }}
                                >
                                    <i class="fa-solid fa-globe px-2 fa-lg" style={{ color: 'white' }}></i>
                                </ListItemIcon>
                            </Tooltip>
                            <ListItemText sx={{ opacity: open ? 1 : 0, marginLeft: '35px' }} >
                                <a className="new_sidebar_list_name" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} onClick={(e) => {
                                    navigate("/dashboard")
                                    setoo(false)
                                    setooo(false)
                                    setoooo(false)
                                }}><div>Dashboard</div></a>
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding sx={{ display: 'block' }} className={window.location.pathname.includes('/allorder') ? "new_sidebar_list_name_active" : ""}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                                position: 'relative'
                            }}

                        >

                            <Tooltip title="Order" placement="right">
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                        position: 'absolute',
                                        top: '33px'
                                    }}
                                >
                                    <i class="fa-solid fa-truck px-2 fa-lg" style={{ color: 'white' }}></i>
                                </ListItemIcon>
                            </Tooltip>
                            <ListItemText sx={{ opacity: open ? 1 : 0, marginLeft: '35px' }} >
                                <a className="new_sidebar_list_name" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} onClick={() => {
                                    navigate("/allorder")
                                    setoo(false)
                                    setooo(false)
                                    setoooo(false)
                                }}><div>Order </div></a>
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                                position: 'relative'
                            }}

                        >
                            <Tooltip title="Products" placement="right">
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                        position: 'absolute',
                                        top: '33px'
                                    }}
                                >
                                    <i class="fa-solid fa-box px-2 fa-lg" style={{ color: 'white' }}></i>
                                </ListItemIcon>
                            </Tooltip>
                            <ListItemText sx={{ opacity: open ? 1 : 0, marginLeft: '35px' }} >
                                <a className='new_sidebar_list_name' style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} onClick={() => {
                                    setoo(!oo)
                                    setooo(false)
                                    setoooo(false)
                                }}><div>Product </div> <div>{oo ? <i class="fa-solid fa-chevron-up"></i> : <i class="fa-solid fa-chevron-down"></i>}</div></a>

                                {
                                    oo ?
                                        <ul className='show-box'>
                                            <li class={window.location.pathname.includes('/addproduct') ? "new_sidebar_list_name_active s" : "new_sidebar_list_name s"}
                                                onClick={() => {
                                                    navigate("/addproduct")

                                                }}>- Add Product</li>
                                            <li class={window.location.pathname.includes('/allproduct') ? "new_sidebar_list_name_active s" : "new_sidebar_list_name s"} onClick={() => {
                                                navigate("/allproduct")

                                            }}>- All Product</li>
                                        </ul> : null
                                }
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding sx={{ display: 'block' }} className={window.location.pathname.includes('/alluser') ? "new_sidebar_list_name_active" : ""}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                                position: 'relative'
                            }}

                        >
                            <Tooltip title="All User" placement="right">
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                        position: 'absolute',
                                        top: '30px'
                                    }}
                                >
                                    <i class="fa-solid fa-user px-2 fa-lg" style={{ color: 'white' }}></i>
                                </ListItemIcon>
                            </Tooltip>
                            <ListItemText sx={{ opacity: open ? 1 : 0, marginLeft: '35px' }} >
                                <a className="new_sidebar_list_name" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} onClick={() => {
                                    navigate("/alluser")
                                    setoo(false)
                                    setooo(false)
                                    setoooo(false)
                                }}><div>All User</div></a>


                            </ListItemText>
                        </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                                position: 'relative'
                            }}

                        >
                            <Tooltip title="Slider" placement="right">
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                        position: 'absolute',
                                        top: '31px'
                                    }}
                                >
                                    <i class="fa-solid fa-house px-2 fa-lg" style={{ color: 'white' }}></i>
                                </ListItemIcon>
                            </Tooltip>
                            <ListItemText sx={{ opacity: open ? 1 : 0, marginLeft: '35px' }} >
                                <a className='new_sidebar_list_name' style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} onClick={() => {
                                    setooo(!ooo)
                                    setoo(false)
                                    setoooo(false)
                                }}><div>Homepage </div> <div>{ooo ? <i class="fa-solid fa-chevron-up"></i> : <i class="fa-solid fa-chevron-down"></i>}</div></a>

                                {
                                    ooo ?
                                        <ul className='show-box'>
                                            <li class={window.location.pathname.includes('/slider') ? "new_sidebar_list_name_active s" : "new_sidebar_list_name s"}
                                                onClick={() => {
                                                    navigate("/slider")
                                                }}>- slider</li>
                                            <li class={window.location.pathname.includes('/slidermobile') ? "new_sidebar_list_name_active s" : "new_sidebar_list_name s"} onClick={() => {
                                                navigate("/slidermobile")

                                            }}>- slider mobile</li>
                                            <li class={window.location.pathname.includes('/categoryimage') ? "new_sidebar_list_name_active s" : "new_sidebar_list_name s"} onClick={() => {
                                                navigate("/categoryimage")

                                            }}>- category mobile</li>
                                        </ul> : null
                                }
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                                position: 'relative'
                            }}

                        >
                            <Tooltip title="Promocode" placement="right">
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                        position: 'absolute',
                                        top: '35px'
                                    }}
                                >
                                    <i class="fa-solid fa-percent px-2 fa-lg" style={{ color: 'white' }}></i>
                                </ListItemIcon>
                             
                            </Tooltip>
                            <ListItemText sx={{ opacity: open ? 1 : 0, marginLeft: '35px' }} >
                                <a className='new_sidebar_list_name' style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} onClick={() => {
                                    setoooo(!oooo)
                                    setooo(false)
                                    setoo(false)
                                }}><div>promocode </div> <div>{oooo ? <i class="fa-solid fa-chevron-up"></i> : <i class="fa-solid fa-chevron-down"></i>}</div></a>

                                {
                                    oooo ?
                                        <ul className='show-box'>
                                            <li class={window.location.pathname.includes('/promocode') ? "new_sidebar_list_name_active s" : "new_sidebar_list_name s"} onClick={() => {
                                                navigate("/promocode")
                                            }}>- promocode</li>
                                            <li class={window.location.pathname.includes('/addpromocode') ? "new_sidebar_list_name_active s" : "new_sidebar_list_name s"} onClick={() => {
                                                navigate("/addpromocode")
                                            }}>- add promocode</li>

                                        </ul> : null
                                }
                            </ListItemText>
                        </ListItemButton>
                     
                    </ListItem>

                    <ListItem disablePadding sx={{ display: 'block' }} className={window.location.pathname.includes('/addnotifications') ? "new_sidebar_list_name_active" : ""}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                                position: 'relative'
                            }}

                        >
                            <Tooltip title="Notifications" placement="right">
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                        position: 'absolute',
                                        top: '33px'
                                    }}
                                >
                                    <i class="fa-solid fa-bell px-2 fa-lg" style={{ color: 'white' }}></i>
                                </ListItemIcon>
                            </Tooltip>
                            <ListItemText sx={{ opacity: open ? 1 : 0, marginLeft: '35px' }} >
                                <a className="new_sidebar_list_name" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} onClick={(e) => {
                                    navigate("/addnotifications")
                                    setoo(false)
                                    setooo(false)
                                    setoooo(false)
                                }}><div>Notifications</div></a>
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                                position: 'relative'
                            }}

                        >
                            <Tooltip title="Logout" placement="right">
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                        position: 'absolute',
                                        top: '33px'
                                    }}
                                >
                                    <i class="fa-solid fa-arrow-right-from-bracket px-2 fa-lg" style={{ color: 'white' }}></i>
                                </ListItemIcon>
                            </Tooltip>
                            <ListItemText sx={{ opacity: open ? 1 : 0, marginLeft: '35px' }} >
                                <a className='new_sidebar_list_name' style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} onClick={(e) => {
                                    navigate("/alluser")
                                    setoo(false)
                                    setooo(false)
                                    setoooo(false)
                                    localStorage.clear()
                                    navigate("/");
                                    e.preventDefault();
                                    dispatch({ type: "OpenALogin" })
                                }}><div>Logout</div></a>
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>

                </List>
            </Drawer>
        </Box>
    );
}