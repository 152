import React, { useState,useEffect } from 'react';
import deletepageone from '../../../images/deletepageone.jpeg';
import deletepagetwo from '../../../images/deletepagetwo.jpeg';
import OtpInput from 'react-otp-input';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
function Deleteaccount() {

    const [number, setnumber] = useState();
    const [otp, setOtp] = useState('');
    const [otpbox, setotpbox] = useState(false);
    const [sendotploading, setsendotploading] = useState(false);
    const [wrongotpalert, setwrongotpalert] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [countdown, setCountdown] = useState(30);
    let navigate = useNavigate()
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClick = () => {
        setwrongotpalert(false)

        setsendotploading(true)
        setotpbox(true)
        setOpen(true)
        var Url = window.API_PREFIX + 'user/issueotp_number'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                {
                    "Number":number,

                }),

        }).then(resp => resp.json()).then(data => {
            console.log(data)
            if (data['status'] === "1") {
                alert(data['message'])
            }
            else
            {alert(data['message'])
            setwrongotpalert(true)
        
        }
        setsendotploading(false)
            
        })


       



        setIsDisabled(true);
        const timer = setInterval(() => {
          setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000);
    
        setTimeout(() => {
          clearInterval(timer);
          setIsDisabled(false);
          setCountdown(30);
        }, 30000);
      };
    
      useEffect(() => {
        if (countdown === 0) {
          setIsDisabled(false);
          setCountdown(30);
        }
      }, [countdown]);
    


    return (
        <div className='container  pb-2' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div className='col-12 col-sm-5'>

                {/* <div className='mt-3 '>
                <h4><strong>STEP 1 :</strong> Go to profile &gt; Delete Acoount</h4><br></br>
                <img style={{ width: '20%', height: 'auto' }} src={deletepageone}></img>
                    </div>

                                     <div className='mt-5'>
                <h4><strong>STEP 2 :</strong> Click Delete Account</h4><br></br>
                <img style={{ width: '20%', height: 'auto' }} src={deletepagetwo}></img>
                             </div> */}

                <h2 className='mt-5'>Delete Your Account</h2>

                <div>
                    <div className="adminlogin_label_name">Enter Your Mobile Number</div>
                    <input
                        pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" maxlength="10"
                        style={{ height: '40px', width: '60%' }}
                        type="text"
                        class="form-control adminlogin_text_search_field"
                        id="inputtext"
                        placeholder="Mobile Number"
                        onChange={(e) => {
                            setnumber(e.target.value);
                        }}
                        
                    />
                </div>

                <div className='mt-3 d-flex justify-content-start'>
                    <button style={{ width: '300px' }} className="adminlogin_login_btn"
                    
                    disabled={isDisabled}
                    onClick={handleClick}
                    >
                        {isDisabled ?`Resend OTP in ${countdown} seconds`:`Sent OTP`}
                    </button>

                </div>








                {
                    otpbox ? <div className='mt-3'>
                        <div className='text-center' style={{ marginBottom: '5px', fontSize: '15px' }}>Verify Your Otp</div>
                        <OtpInput
                            inputStyle={{
                                height: "3rem",
                                width: "50%",
                                borderRadius: "10px",
                                textalign: "center",
                                fontweight: "700",
                                backgroundColor: "white",
                                border: "2px solid #483a15",
                                fontsize: "22px",
                                color: "#483a15",
                                display: "flex",
                                justifyContent: "center"
                            }}
                            value={otp}
                            onChange={setOtp}
                            numInputs={4}
                            renderSeparator={<span>-</span>}
                            renderInput={(props) => <input {...props} />}
                        />
                        {/* <div className='text-end' style={{ textDecoration: 'underline', cursor: 'pointer' }}>Resend Otp</div> */}
                    </div>
                        : null
                }

                {/* <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you Want to sure Delete Your Account ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Disagree</Button>
                        <Button onClick={handleClose} autoFocus>
                            Agree
                        </Button>
                    </DialogActions>
                </Dialog> */}


                {wrongotpalert ? <Alert severity="error" className='mt-3'>Incorrect OTP</Alert> : null}

                <div className='text-center'>
                    {sendotploading ? <div className='loginpage_spinner_loader'><CircularProgress color="success" className='spinner' /></div> : null}
                </div>
                {otpbox?
                <div className='mt-3 d-flex justify-content-start'>
                    <button style={{ width: '300px' }} className="adminlogin_login_btn"
                    
                        onClick={(e) => {
                      
                            var Url = window.API_PREFIX + 'user/delete_request'
                            fetch(Url, {
                                method: 'POST',
                                headers: {
                                    'Content-type': 'application/json',
                                },
                                body: JSON.stringify(
                                    {
                                        "Number":number,
                                        "OTP":otp,
                    
                                    }),
                    
                            }).then(resp => resp.json()).then(data => {
                                console.log(data)
                                if (data['status'] === "1") {
                                    alert(data['message'])
                                    navigate("/")
                                }
                                else
                                {alert(data['message'])
                                setwrongotpalert(true)
                            
                            }
                    
                                
                            })
                    

                            
                        }}
                    >
                        Continue
                    </button>

                </div>:null
}
            </div>


        </div>
    )
}

export default Deleteaccount;