import { Outlet } from "react-router-dom";
import "./Adminlayout.css";
import Sidebar from "./AdminSideBar/Sidebar";
import AdminLogin from "./AdminComponent/AdminLogin/AdminLogin";
import { useStateValue } from "../StateProviders";
import { useEffect, useState } from "react";
import Adminheader from "./AdminComponent/Adminheader/Adminheader";

import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
// import Sidebar from "./AdminSideBar/Sidebar";
import Sidebartwo from "./AdminSideBar/Sidebartwo";
import Newsidebar from "./AdminSideBar/Newsidebar";
import Sidebarthree from "./AdminSideBar/Sidebarthree";

export default function Adminlayout() {
  const [{ ShowAdminLoginPop, NewNotification }, dispatch] = useStateValue();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("Token") === null) {
      dispatch({
        type: "OpenALogin",
      });
    } else {
      dispatch({
        type: "CloseALogin",
      });
    }
  }, []);
  // const [{ CloseLogin }, dispatch] = useStateValue();

  // const handleClick = () => {
  //   setOpen(true);
  // };
  useEffect(() => {
    if (NewNotification) {
      setOpen(true)
    }
  }, [])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const action = (
    <div>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </div>
  );


  return (
    <>
      {ShowAdminLoginPop ? (

        <div className=" d-flex">
          {/* <Sidebar /> */}
          {/* <Sidebartwo /> */}
          <Sidebarthree />
          {/* <Newsidebar /> */}
          <div className="adminpanel_outletmain_div">
            <Adminheader />
            <div style={{ marginTop: '80px', marginLeft:'80px' }}>
              <Outlet />
              <Snackbar
                style={{ cursor: 'pointer' }}
                open={open}
                autoHideDuration={4000}
                onClose={handleClose}
                message={<div>
                  New Notification Received
                </div>}
                action={action}
              />


            </div>
          </div>
        </div>
      ) : (
        <AdminLogin />
      )}
    </>
  );
}
