import React, { useState, useEffect } from 'react';
import EmojiPicker from 'emoji-picker-react';
import './Addnotifications.css'
import { Transfer } from 'antd';

function Addnotifications() {





    const [chosenEmoji, setChosenEmoji] = useState(null);
    const [title, setTitle] = useState("")
    const [textInput, setTextInput] = useState('');
    const [isAllUser, setIsAllUser] = useState(false)
    const token = localStorage.getItem("Token")

    const handleEmojiClick = (event, emojiObject) => {
        setChosenEmoji(emojiObject);
        if (textInput !== '') {
            setTextInput(textInput + emojiObject.emoji);
        } else {
            setTextInput(emojiObject.emoji);
        }
    };

    const handleInputChange = (event) => {
        setTextInput(event.target.value);
    };

    const handleTitleChange = (e) => {
        setTitle(e.target.value);
    }


    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState([]);

    const handleCheckboxChange = (event) => {
        setIsAllUser(event.target.checked);
      };

    const handleFilter = (inputValue, option) => {
    return option.Name.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
    }

    const handleChange = (newTargetKeys) => {
        setSelectedUser(newTargetKeys);
      };

    useEffect(() => {
        var Url = window.API_PREFIX + "admin/all_user";
        fetch(Url, {
            method: "POST",
            headers: {
                "Content-type": "application/json",
            },
        })
            .then((resp) => resp.json())
            .then((data) => {
                setUsers([...data["users"]])
            });
    }, []);

    const handleAddNotification = () => {
        var Url = window.API_PREFIX + "admin/add_notification";
        fetch(Url, {
            method: "POST",
            headers: {
                "Content-type": "application/json",
            },
            body: JSON.stringify({
                "Token": token,
                "Message": textInput,
                "Title": title,
                "IsAll": isAllUser,
                "User": !isAllUser ? selectedUser : []
            })
        })
            .then((resp) => resp.json())
            .then((data) => {
                if(data["status"] === "1") {
                    setTitle("")
                    setTextInput("")
                    setIsAllUser(false)
                    setSelectedUser("")
                    alert("Notification sent successfully")
                } 
                else{
                    alert("Notification failed")

                }
            });
    }


    return (
        <div className='p-3'>

            <h4>Add Notifications</h4>
            <div>
                <div class="content d-flex align-items-center mb-3">
                    <label class="checkBox d-flex">
                        <input id="ch1" type="checkbox" checked={isAllUser} onChange={handleCheckboxChange} />
                        <div class="transition"></div>
                    </label>
                    <span className='mx-3'>All Users</span>
                </div>
                {!isAllUser?
                <div>
                    <Transfer
                        dataSource={users}
                        showSearch
                        listStyle={{
                            width: 300,
                            height: 400,
                        }}
                        filterOption={handleFilter}
                        targetKeys={selectedUser}
                        onChange={handleChange}
                        render={(item) => `${item.id}. ${item.Name}`}
                        rowKey={(item) => `${item.id}`}
                        showSelectAll={true}
                    />
                </div>:null}
            </div>



            <div className='mt-3'>

                <div class="form-floating mb-3">
                    <input type="text" class="form-control" id="floatingInput" placeholder="Title" style={{ width: '320px' }} value={title} onChange={handleTitleChange} />
                    <label for="floatingInput">Title</label>
                </div>

                <div>
                    <textarea class="form-control" placeholder="Comments" id="floatingTextarea2" style={{ width: '320px' }} value={textInput}
                        onChange={handleInputChange}></textarea>
                    {/* <EmojiPicker style={{ marginTop: '10px' }} onEmojiClick={handleEmojiClick} /> */}
                </div>
                <button type="button" class="btn allproducts_addnew_button mt-3" onClick={handleAddNotification}>Post</button>
            </div>

        </div>
    )
}

export default Addnotifications