import "./TrashOrder.css";
import { Empty, Modal, Table } from "antd";
import React, { useEffect, useState } from "react";
import Loader from "../../Loader";
import { DeleteFilled, RollbackOutlined } from "@ant-design/icons";

const TrashOrder = ({ open, setOpen, isRestored, setIsRestored }) => {
  const [orderData, setOrderData] = useState([]);
  const [loading, setLoading] = useState(false);
  const columns = [
    {
      title: "Order",
      dataIndex: "OrderGroupId",
      render(text) {
        return {
          props: {
            style: { fontSize: "16px", textAlign: "center", color: "#483a15" },
          },
          children: <div style={{ fontWeight: "600" }}>{text}</div>,
        };
      },
    },
    {
      title: "Product Name",
      dataIndex: "Product_Name",
      render(text) {
        return {
          props: {
            style: { fontSize: "16px", textAlign: "center" },
          },
          children: (
            <div
              style={{
                textAlign: "center",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                maxWidth: "250px",
              }}
            >
              {text}
            </div>
          ),
        };
      },
    },
    {
      title: "UserName",
      dataIndex: "UserName",
      render(text) {
        return {
          props: {
            style: { fontSize: "16px", textAlign: "center" },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Date_Time",
      dataIndex: "Date_Time",
      render(text) {
        return {
          props: {
            style: { fontSize: "16px", textAlign: "center" },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Status",
      dataIndex: "Status",
      render(text) {
        return {
          props: {
            style: { fontSize: "16px", textAlign: "center" },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Total",
      dataIndex: "TotalAmount",
      render(text) {
        return {
          props: {
            style: { fontSize: "16px", textAlign: "center" },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Restore",
      dataIndex: "Restore",
      render: (text, record, index) => (
        <>
          <RollbackOutlined
            style={{
              fontSize: "18px",
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              var Url = window.API_PREFIX + "admin/untrash_order";

              fetch(Url, {
                method: "POST",
                headers: {
                  "Content-type": "application/json",
                },
                body: JSON.stringify({
                  Token: localStorage.getItem("Token"),
                  OrderGroupId: record.OrderGroupId,
                }),
              })
                .then((resp) => resp.json())
                .then((Revdata) => {
                  if (Revdata["status"] === "1") {
                    var tmpOrder = orderData;
                    tmpOrder.splice(index, 1);
                    setOrderData([...tmpOrder]);
                    setIsRestored(!isRestored);
                  }
                });
            }}
          />
        </>
      ),
    },
    {
      title: "Delete",
      dataIndex: "Delete",
      render: (text, record, index) => (
        <>
          <DeleteFilled
            style={{
              color: "#e11f48",
              fontSize: "18px",
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              var Url = window.API_PREFIX + "admin/delete_order";

              fetch(Url, {
                method: "POST",
                headers: {
                  "Content-type": "application/json",
                },
                body: JSON.stringify({
                  Token: localStorage.getItem("Token"),
                  OrderGroupId: record.OrderGroupId,
                }),
              })
                .then((resp) => resp.json())
                .then((Revdata) => {
                  if (Revdata["status"] === "1") {
                    var tmpOrder = orderData;
                    tmpOrder.splice(index, 1);
                    setOrderData([...tmpOrder]);
                  }
                });
            }}
          />
        </>
      ),
    },
  ];

  const getTrashedOrderData = () => {
    var Url = window.API_PREFIX + "admin/alltrash_order";
    setLoading(true);
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("Token"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data["status"] === "1") {
          setOrderData(data["SingleUserOrderData"]);
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    getTrashedOrderData();
  }, []);

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal
        title="Deleted Orders"
        className="TrashedOrder_Modal"
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={"85vw"}
      >
        <Table
          className="all_products_tables"
          columns={columns}
          dataSource={orderData}
          locale={{
            emptyText: loading ? <Loader /> : <Empty />,
          }}
        />
      </Modal>
    </div>
  );
};

export default TrashOrder;
