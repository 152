import React from 'react'

function Contactus() {
    return (
        <div className='container pb-3 d-flex justify-content-center align-items-center' style={{ fontSize: '16px' }}>
            <div className='w-75'>


                <div className="mt-3">
                    <div className="adminlogin_label_name">Name</div>
                    <div>
                        <input
                            type="text"
                            class="form-control adminlogin_text_search_field w-50"
                            id="inputtext"
                            placeholder="Enter Your Name"
                        />
                    </div>
                </div>

                <div className="mt-3">
                    <div className="adminlogin_label_name">Number</div>
                    <div>
                        <input
                            type="number"
                            class="form-control adminlogin_text_search_field w-50"
                            id="inputtext"
                            placeholder="Enter Your Number"
                        />
                    </div>
                </div>

                <div className="mt-3">
                    <div className="adminlogin_label_name">Mail</div>
                    <div>
                        <input
                            type="mail"
                            class="form-control adminlogin_text_search_field w-50"
                            id="inputtext"
                            placeholder="Enter Your Mail"
                        />
                    </div>
                </div>

                <div class="mt-3">
                    <label for="exampleFormControlTextarea1" class="form-label">Additional Message</label>
                    <textarea class="form-control w-50" id="exampleFormControlTextarea1" rows="3"></textarea>
                </div>



                <button
                    className="adminlogin_login_btn w-50 mt-3"

                >
                    Send
                </button>


            </div>
        </div>
    )
}

export default Contactus