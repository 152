import './AddPromocode.css'
import React, { Component } from 'react';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStateValue } from '../../../StateProviders';
// import Item from '../../../User/UserComponent/HomeLayout/Item';

function AddPromocode() {
    let navigate = useNavigate();
    const [{ }, dispatch] = useStateValue();
    const params = useParams()
    const { CID } = params
    console.log(CID)
    const [discountTypeDropdown, setDiscountTypeDropdown] = useState([
        {
            dropdownname: "Flat Cart Discount"
        },
        {
            dropdownname: "Percentage Discount"
        },

    ])
    const [selectedDiscountType, setSelectedDiscountType] = useState(discountTypeDropdown[0].dropdownname);
    const [activeCouponDataTab, setActiveCouponDataTab] = useState([true, false, false])

    // This variable passed in APIs
    //Tab 1
    const [IsPerDiscountType, setIsPerDiscountType] = useState(false)




    const [couponCode, setcouponCode] = useState('')
    const [couponDesc, setcouponDesc] = useState('')
    const [disPercent, setdisPercent] = useState("1")
    const [couponAmt, setcouponAmt] = useState("")
    const [couponExpDate, setcouponExpDate] = useState("")
    const [minSpend, setminSpend] = useState("")
    const [Individualuse, setIndividualuse] = useState(false)
    const [UsageLimitpercoupon, setUsageLimitpercoupon] = useState('')
    const [UsingLimitPerUser, setUsingLimitPerUser] = useState('')


    function PublishFun() {
        if (window.location.pathname.includes("/editpromocode/") && CID !== undefined) {
            // var Isindivisual = "0"
            // if(Individualuse)
            // {
            //     Isindivisual = "1"
            // }


            var Url = window.API_PREFIX + 'admin/edit_coupon'

            var JsonSend = {
                Token: localStorage.getItem("Token"),
                id: CID,
                CoupenName: couponCode,
                DiscountType: IsPerDiscountType,
                Description: couponDesc,
                CoupenValue: couponAmt,
                Percentage: disPercent,
                CoupenExpiryDate: couponExpDate,
                MinimumSpend: minSpend,
                Isindivisual: Individualuse,

                UsageLimitPerCoupen: UsageLimitpercoupon,
                UsageLimitPerUser: UsingLimitPerUser,
                Issale: true
            }
            console.log(JsonSend)

            fetch(Url, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify(
                    JsonSend),

            }).then(resp => resp.json()).then(data => {
                if (data['status'] === "1") {
                    alert('Coupon ' + couponCode + ' Edited successfully')
                    navigate("/promocode")
                }
                else {
                    alert('Failed')
                }
            })
        }
        else {
            // var Isindivisual = "0"
            // if(Individualuse)
            // {
            //     Isindivisual = "1"
            // }


            var Url = window.API_PREFIX + 'admin/add_new_coupon'

            var JsonSend = {
                Token: localStorage.getItem("Token"),
                CoupenName: couponCode,
                DiscountType: IsPerDiscountType,
                Description: couponDesc,
                CoupenValue: couponAmt,
                Percentage: disPercent,
                CoupenExpiryDate: couponExpDate,
                MinimumSpend: minSpend,
                Isindivisual: Individualuse,

                UsageLimitPerCoupen: UsageLimitpercoupon,
                UsageLimitPerUser: UsingLimitPerUser,
                Issale: true
            }
            console.log(JsonSend)

            fetch(Url, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify(
                    JsonSend),

            }).then(resp => resp.json()).then(data => {
                console.log(data)
                if (data['status'] === "1") {
                    alert('Coupon ' + couponCode + ' Added successfully')
                    navigate("/promocode")
                }
                else {
                    alert('Failed')
                }
            })
        }
    }







    useEffect(() => {


        console.log(window.location.pathname)
        console.log(CID)
        if (window.location.pathname.includes("/editpromocode/") && CID !== undefined) {

            var Url = window.API_PREFIX + 'admin/view_coupon'
            fetch(Url, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify(
                    {
                        "Token": localStorage.getItem("Token"),
                        "id": CID
                    }),

            }).then(resp => resp.json()).then(data => {
                console.log(data)
                if (data['status'] === "1") {
                    setcouponCode(data['Name'])
                    setcouponDesc(data['Description'])
                    if (data["DiscountType"]) {
                        setIsPerDiscountType(true)
                        setdisPercent(data['Percentage'])
                        setSelectedDiscountType("Percentage Discount")
                    }
                    else {
                        setIsPerDiscountType(false)
                        setSelectedDiscountType("Flat Cart Discount")

                    }
                    setcouponAmt(data['CoupenValue'])
                    setcouponExpDate(data['ExpiryDate'])
                    setminSpend(data['MinimumSpend'])
                    setIndividualuse(data['IsIndivisual'])
                    setUsageLimitpercoupon(data['UsageLimitPerCoupen'])
                    setUsingLimitPerUser(data['UsageLimitPerUser'])

                }
                else {
                    dispatch({
                        type: "OpenALogin"
                    })
                    localStorage.clear()
                    navigate("/")
                }
            })
        }
        //         else
        // {
        //         var Url = window.API_PREFIX + 'order/allorder'
        // 		fetch(Url, {
        // 			method: 'POST',
        // 			headers: {
        // 				'Content-type': 'application/json',
        // 			},
        // 			body: JSON.stringify(
        // 				{ "Token": localStorage.getItem("Token")}),
        // 		}).then(resp => resp.json()).then(data => {
        // 			console.log(data)
        // 			if (data['status'] == "1") {
        //                 setOrderData([...data["AllOrderData"]])
        //                 // console.log(window.location.pathname)
        //             }
        //             else{
        //                 dispatch({
        //                     type: "OpenALogin"
        //                 })
        //                 localStorage.clear()
        //                 navigate("/")
        //             }
        //         })}
    }, [])



    return (
        <>
            <div className='addpromocode_main_division'>
                <div className='addpromocode_main_sub_division'>
                    <div className='addpromocode_add_new_product_title'>{CID === undefined ? "Add" : "Edit"} Promocode</div>

                    {/* <form onSubmit={PublishFun}> */}
                        <div className='addpromocode_sub_main_division'>
                            <div className='addpromocode_add_new_product_division'>
                                <input class="addpromocode_formcontrol form-control" type="text" placeholder="Coupon Code" value={couponCode}
                                    onChange={(e) => {
                                        setcouponCode(e.target.value);
                                    }}
                                />
                                {/* <div className='addpromocode_permalink_breadcrums'>
                                    <button type="button" class="addpromocode_generate_code_btn">Generate Coupon Code</button>
                                </div> */}
                                <textarea class="addpromocode_description_form form-control" id="exampleFormControlTextarea1" rows="3" placeholder='Description (Option)' value={couponDesc}
                                    onChange={(e) => {
                                        setcouponDesc(e.target.value);
                                    }}
                                ></textarea>


                                <div className='addpromocode_product_data_main_division'>
                                    <div className='addpromocode_product_data'>
                                        <div className='addpromocode_product_data_title'>Coupon Data</div>
                                    </div>

                                    <div className='addpromocode_category_list_details'>
                                        <div className='addpromocode_category_list'>
                                            <div className={activeCouponDataTab[0] ? 'addpromocode_category_active' : 'addpromocode_category'}
                                                onClick={() => {
                                                    setActiveCouponDataTab([true, false, false])
                                                }}
                                            ><i class="fa-solid fa-wrench"></i> General</div>
                                            <div className={activeCouponDataTab[1] ? 'addpromocode_category_active' : 'addpromocode_category'}
                                                onClick={() => {
                                                    setActiveCouponDataTab([false, true, false])
                                                }}
                                            ><i class="fa-solid fa-wrench"></i> Usage Restriction</div>
                                            <div className={activeCouponDataTab[2] ? 'addpromocode_category_active' : 'addpromocode_category'}
                                                onClick={() => {
                                                    setActiveCouponDataTab([false, false, true])
                                                }}><i class="fa-solid fa-briefcase"></i>Usage Limits</div>
                                        </div>

                                        {activeCouponDataTab[0] ? <div className='addpromocode_coupondata_main_division'>
                                            <div className='addpromocode_coupondata_division'>
                                                <div className='addpromocode_coupondata_left_side'>
                                                    <p>discount type</p>
                                                </div>
                                                <div className='addpromocode_coupondata_right_side'>
                                                    <div class="dropdown">
                                                        <button class="btn addpromocode_discounttype_dropdown dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            {selectedDiscountType}
                                                        </button>
                                                        <ul class="dropdown-menu">
                                                            {
                                                                discountTypeDropdown.map((item) => {
                                                                    return (
                                                                        <li> <a className={selectedDiscountType === item.dropdownname ? 'dropdown-item addpromocode_discounttype_dropdown_menu_active' : 'dropdown-item addpromocode_discounttype_dropdown_menu'}
                                                                            onClick={() => {
                                                                                setSelectedDiscountType(item.dropdownname)
                                                                                if (item.dropdownname === "Flat Cart Discount") { setIsPerDiscountType(false) }
                                                                                else { setIsPerDiscountType(true) }
                                                                            }
                                                                            }> {item.dropdownname}</a>
                                                                        </li>
                                                                    )
                                                                }
                                                                )
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>

                                            </div>
                                            {IsPerDiscountType ?
                                                <div className='editpromocode_coupondata_division'>
                                                    <div className='editpromocode_coupondata_left_side'>
                                                        <p>Enter Percentage</p>
                                                    </div>
                                                    <div className='editpromocode_coupondata_right_side'>
                                                        <input type="number" value={disPercent} min={1} max={100} maxLength={3} required class="editpromocode_couponamount_text_field form-control" placeholder="Enter Percentage"
                                                            onChange={(e) => {
                                                                if (e.target.value >= 0 && e.target.value <= 100 || e.target.value === "") {
                                                                    setdisPercent(e.target.value);

                                                                }
                                                            }}
                                                        />
                                                    </div>

                                                </div>
                                                : null}
                                            <div className='addpromocode_coupondata_division'>
                                                <div className='addpromocode_coupondata_left_side'>
                                                    <p>Coupon Amount</p>
                                                </div>
                                                <div className='addpromocode_coupondata_right_side'>
                                                    <input type="number" required class="addpromocode_couponamount_text_field form-control" placeholder="No minimum" value={couponAmt}
                                                        onChange={(e) => {
                                                            setcouponAmt(e.target.value)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            {/* <div className='addpromocode_coupondata_division'>
                                                <div className='addpromocode_coupondata_left_side'>  <p>Allow Free Shipping</p></div>
                                                <div class="addpromocode_coupondata_right_side d-flex">
                                                    <input class="form-check-input addpromocode_allow_free_shipping_chechbox" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label class="form-check-label addpromocode_allow_free_shipping_chechbox_label" for="flexCheckDefault">
                                                        Check this box if the coupon grants free shipping. A <span>free shipping method</span> must be enabled in your shipping zone and be set to require "a valid free shipping coupon" (see the "Free Shipping Requires" setting).
                                                    </label>
                                                </div>
                                            </div> */}
                                            <div className='addpromocode_coupondata_division'>
                                                <div className='addpromocode_coupondata_left_side'>  <p>Coupon expiry date</p></div>
                                                <div class="addpromocode_coupondata_right_side">
                                                    <input type="date" className='addpromocode_couponamount_text_field' id="expdate" name="expdate" value={couponExpDate}
                                                        onChange={(e) => {
                                                            setcouponExpDate(e.target.value)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div> : null}

                                        {activeCouponDataTab[1] ? <div className='addpromocode_usage_main_division'>
                                            <div className='addpromocode_usage_division'>
                                                <div className='addpromocode_usage_sub_main_division'>
                                                    <div className='addpromocode_minimum_spend_title'>Minimum spend</div>
                                                    <input type="email" class="addpromocode_minimum_spend_title_text_field form-control" placeholder="No minimum" value={minSpend}
                                                        onChange={(e) => {
                                                            setminSpend(e.target.value)
                                                        }}
                                                    />
                                                    <div className='addpromocode_question_icon'><i class="fa-solid fa-circle-question"></i></div>
                                                </div>
                                                {/* <div className='addpromocode_usage_sub_main_division'>
                                                    <div className='addpromocode_minimum_spend_title'>Maximum spend</div>
                                                    <input type="email" class="addpromocode_minimum_spend_title_text_field form-control" placeholder="No muximum" />
                                                    <div className='addpromocode_question_icon'><i class="fa-solid fa-circle-question"></i></div>
                                                </div> */}

                                                <div className='addpromocode_usage_checkbox_sub_main_division'>
                                                    <div className='addpromocode_minimum_spend_title'>Individual use only</div>
                                                    <div class="addpromocode_check_box_division ">
                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={Individualuse}
                                                            onChange={(e) => {
                                                                setIndividualuse(e.target.checked)
                                                            }}
                                                        />
                                                        <label class="form-check-label" for="flexCheckDefault">
                                                            Check this box if the coupon cannot be used in conjunction with other coupons.
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>






                                        </div> : null}

                                        {activeCouponDataTab[2] ? <div className='addpromocode_limit_main_division'>
                                            <div className='addpromocode_limit_sub_main_division'>
                                                <div className='addpromocode_limit_minimum_spend_title'>Usage limit per coupon</div>
                                                <input type="email" class="addpromocode_limit_minimum_spend_title_text_field form-control" placeholder="Unlimited usage" value={UsageLimitpercoupon}
                                                    onChange={(e) => {
                                                        setUsageLimitpercoupon(e.target.value)
                                                    }}
                                                />
                                                <div className='addpromocode_limit_question_icon'><i class="fa-solid fa-circle-question"></i></div>
                                            </div>

                                            <div className='addpromocode_limit_sub_main_division'>
                                                <div className='addpromocode_limit_minimum_spend_title'>Usage limit per user</div>
                                                <input type="email" class="addpromocode_limit_minimum_spend_title_text_field form-control" placeholder="Unlimited usage" value={UsingLimitPerUser}
                                                    onChange={(e) => {
                                                        setUsingLimitPerUser(e.target.value)
                                                    }}
                                                />
                                                <div className='addpromocode_limit_question_icon'><i class="fa-solid fa-circle-question"></i></div>
                                            </div>

                                        </div> : null}


                                    </div>
                                </div>

                            </div>
                            {/* <div className='addpromocode_main_Social_division'> */}
                            {/* <div className='addpromocode_main_publish_division'> */}
                            {/* <div className='addpromocode_publish_data'>
                                        <div className='addpromocode_publish_title'>Publish</div>
                                    </div> */}

                            {/* <div className='addpromocode_publish_details_field'>

                                        <div className='addpromocode_edit_status'>
                                            <div className='addpromocode_status_icon'><i class="fa-solid fa-map-pin"></i></div>
                                            <div className='addpromocode_status'>Status:</div>
                                            <div className='addpromocode_draft'>Draft</div>
                                            <div className='addpromocode_edit'><u>Edit</u></div>
                                        </div>

                                        <div className='addpromocode_edit_status'>
                                            <div className='addpromocode_status_icon'><i class="fa-solid fa-eye"></i></div>
                                            <div className='addpromocode_status'>Visibility:</div>
                                            <div className='addpromocode_draft'>Public</div>
                                            <div className='addpromocode_edit'><u>Edit</u></div>
                                        </div>

                                        <div className='addpromocode_edit_status'>
                                            <div className='addpromocode_status_icon'><i class="fa-solid fa-briefcase"></i></div>
                                            <div className='addpromocode_status'>Publish:</div>
                                            <div className='addpromocode_draft'>Immediately</div>
                                            <div className='addpromocode_edit'><u>Edit</u></div>
                                        </div>
                                    </div> */}

                            <div className='addpromocode_last_division_new_draft'>
                                <button type="submit" class="addpromocode-btn-publish"
                                    onClick={PublishFun}
                                >Publish</button>
                            </div>
                            {/* </div> */}





                            {/* </div> */}
                        </div>
                    {/* </form> */}
                </div>
            </div>
        </>
    )
}
export default AddPromocode;