import './Sidebar.css'
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import { useStateValue } from "../../StateProviders";
import kandariwalamainlogo from '../../../../src/images/logotwo.png';



import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';



function Sidebar() {


  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  const [expanded, setExpanded] = React.useState(false);


  const style = {
    background: '#8f7d4f',
  }

  const drawerWidth = 247;

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    background: '#8f7d4f'
  }));

  const theme = useTheme();

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };


  const [{ ShowAdminLoginPop }, dispatch] = useStateValue();

  let navigate = useNavigate()


  const [oo, setoo] = React.useState(false);
  const [ooo, setooo] = React.useState(false);
  const [oooo, setoooo] = React.useState(false);



  return (
    <>
      <div className="sidebar">

        <div className='adminlheader_logomain_div'>
          <div className='adminheader_logo_2'>
            <img className='adminheader_logo' src={kandariwalamainlogo}></img>
          </div>
        </div>

        <div className='sidebar2'>

          <li className={window.location.pathname.includes('/allorder') ? 'new_sidebar_list_name_active' : "new_sidebar_list_name"}
            onClick={() => {
              navigate("/allorder")
              setoo(false)
              setooo(false)
              setoooo(false)
            }} ><i class="fa-solid fa-truck px-2"></i>
            Order
          </li>

          <li>
            <a className='new_sidebar_list_name' style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} onClick={() => {
              setoo(!oo)
              setooo(false)
              setoooo(false)
            }}><div><i class="fa-solid fa-box px-2"></i> Product</div>  <div>{oo ? <i class="fa-solid fa-chevron-up"></i> : <i class="fa-solid fa-chevron-down"></i>}</div></a>

            {
              oo ?
                <ul className='show-box'>
                  <li class={window.location.pathname.includes('/addproduct') ? "new_sidebar_list_name_active" : "new_sidebar_list_name"}
                    onClick={() => {
                      navigate("/addproduct")

                    }}>- Add Product</li>
                  <li class={window.location.pathname.includes('/allproduct') ? "new_sidebar_list_name_active" : "new_sidebar_list_name"} onClick={() => {
                    navigate("/allproduct")

                  }}>- All Product</li>
                </ul> : null
            }
          </li>

          <li className={window.location.pathname.includes('/alluser') ? 'new_sidebar_list_name_active' : "new_sidebar_list_name"}
            onClick={() => {
              navigate("/alluser")
              setoo(false)
              setooo(false)
              setoooo(false)
            }} ><i class="fa-solid fa-user px-2"></i>
            All User
          </li>

          <li>
            <a className="new_sidebar_list_name" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} onClick={() => {
              setooo(!ooo)
              setoo(false)
              setoooo(false)
            }}><div><i class="fa-solid fa-house px-2"></i> Homepage</div> <div>{ooo ? <i class="fa-solid fa-chevron-up"></i> : <i class="fa-solid fa-chevron-down"></i>}</div></a>

            {
              ooo ?
                <ul className='show-box'>
                  <li class={window.location.pathname.includes('/slider') ? "new_sidebar_list_name_active" : "new_sidebar_list_name"}
                    onClick={() => {
                      navigate("/slider")
                    }}>- slider</li>
                  <li class={window.location.pathname.includes('/slidermobile') ? "new_sidebar_list_name_active" : "new_sidebar_list_name"} onClick={() => {
                    navigate("/slidermobile")

                  }}>- slider mobile</li>
                  <li class={window.location.pathname.includes('/categoryimage') ? "new_sidebar_list_name_active" : "new_sidebar_list_name"} onClick={() => {
                    navigate("/categoryimage")

                  }}>- category mobile</li>
                </ul> : null
            }
          </li>

          <li>
            <a className="new_sidebar_list_name" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} onClick={() => {
              setoooo(!oooo)
              setooo(false)
              setoo(false)
            }}><div> <i class="fa-solid fa-percent px-2"></i> promocode </div> <div>{oooo ? <i class="fa-solid fa-chevron-up"></i> : <i class="fa-solid fa-chevron-down"></i>}</div></a>

            {
              oooo ?
                <ul className='show-box'>
                  <li class={window.location.pathname.includes('/promocode') ? "new_sidebar_list_name_active" : "new_sidebar_list_name"} onClick={() => {
                    navigate("/promocode")
                  }}>- promocode</li>
                  <li class={window.location.pathname.includes('/addpromocode') ? "new_sidebar_list_name_active" : "new_sidebar_list_name"} onClick={() => {
                    navigate("/addpromocode")
                  }}>- add promocode</li>

                </ul> : null
            }
          </li>


          <li className='new_sidebar_list_name'
            onClick={(e) => {
              localStorage.clear()
              navigate("/");
              e.preventDefault();
              dispatch({ type: "OpenALogin" })
            }}><i class="fa-solid fa-arrow-right-from-bracket px-2"></i>
            logout
          </li>

        </div>
      </div >

      {/* <div className='sidebar_two'>
        <Box sx={{ display: 'flex' }}>
          <IconButton
            className='icon_sidebar'
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >

            <span className='bar_icon'>
              <span className='bar_span_one'></span>
              <span className='bar_span_two'></span>
              <span className='bar_span_three'></span>
            </span>

          </IconButton>

          <Drawer
            className='drawer_responsive'
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
              },
            }}
            variant="persistent"
            anchor="left"
            open={open}
          >
            <DrawerHeader >
              <div>wdw</div>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon style={{ color: "#e11f48" }} /> : <ChevronRightIcon />}
              </IconButton>
            </DrawerHeader>


            <AccordionSummary className={window.location.pathname.includes('/allorder') ? 'sidebar_list_name_active' : "sidebar_list_name"}
              aria-controls="panel1a-content"
              id="panel1a-header"
              onClick={() => {
                navigate("/allorder")
              }}>
              <Typography style={{ fontFamily: 'Poppins, sans-serif' }}
              > <i class="fa-solid fa-truck px-2"></i>Order</Typography>
            </AccordionSummary>

            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className='sidebar_list_name_division' >
              <AccordionSummary className='sidebar_list_name'
                style={style}
                expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <Typography style={{ fontFamily: 'Poppins, sans-serif' }} > <i class="fa-solid fa-box px-2"></i> Product</Typography>
              </AccordionSummary>
              <AccordionDetails
                style={style}>
                <ul>
                  <li class={window.location.pathname.includes('/allproduct') ? "sidebar_subname_lists_active" : "sidebar_subname_lists"}
                    onClick={() => {
                      navigate("/allproduct")
                    }}
                  > <span>-</span>All Product</li>
                  <li class={window.location.pathname.includes('/addproduct') ? "sidebar_subname_lists_active" : "sidebar_subname_lists"}
                    onClick={() => {
                      navigate("/addproduct")
                    }}
                  ><span>-</span>Add Product</li>

                </ul>
              </AccordionDetails>
            </Accordion>

            <AccordionSummary className={window.location.pathname.includes('/alluser') ? 'sidebar_list_name_active' : "sidebar_list_name"}
              aria-controls="panel1a-content"
              id="panel1a-header"
              onClick={() => {
                navigate("/alluser")
              }}>
              <Typography style={{ fontFamily: 'Poppins, sans-serif' }}  > <i class="fa-solid fa-user px-2"></i>All User</Typography>
            </AccordionSummary>

            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} className='sidebar_list_name_division'>
              <AccordionSummary className='sidebar_list_name'
                style={style}
                expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <Typography style={{ fontFamily: 'Poppins, sans-serif' }} > <i class="fa-solid fa-house px-2"></i> Homepage</Typography>
              </AccordionSummary>
              <AccordionDetails
                style={style}>
                <ul>
                  <li class={window.location.pathname.includes('/slider') ? "sidebar_subname_lists_active" : "sidebar_subname_lists"}
                    onClick={() => {
                      navigate("/slider")
                    }}
                  > <span>-</span>Slider</li>

                  <li class={window.location.pathname.includes('/slidermobile') ? "sidebar_subname_lists_active" : "sidebar_subname_lists"}
                    onClick={() => {
                      navigate("/slidermobile")
                    }}
                  > <span>-</span>SliderMobile</li>

                  <li class={window.location.pathname.includes('/categoryimage') ? "sidebar_subname_lists_active" : "sidebar_subname_lists"}
                    onClick={() => {
                      navigate("/categoryimage")
                    }}
                  > <span>-</span>Category Image</li>
                </ul>
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} className='sidebar_list_name_division'>
              <AccordionSummary className='sidebar_list_name'
                style={style}
                expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <Typography style={{ fontFamily: 'Poppins, sans-serif' }} > <i class="fa-solid fa-percent px-2"></i>Promocode</Typography>
              </AccordionSummary>
              <AccordionDetails
                style={style}>
                <ul>
                  <li class={window.location.pathname.includes('/promocode') ? "sidebar_subname_lists_active" : "sidebar_subname_lists"}
                    onClick={() => {
                      navigate("/promocode")
                    }}
                  > <span>-</span>Promocode</li>
                  <li class={window.location.pathname.includes('/addpromocode') ? "sidebar_subname_lists_active" : "sidebar_subname_lists"}
                    onClick={() => {
                      navigate("/addpromocode")
                    }}
                  ><span>-</span>Add Promocode</li>
                </ul>
              </AccordionDetails>
            </Accordion>

            <AccordionSummary className='sidebar_list_name'
              aria-controls="panel1a-content"
              id="panel1a-header"
              onClick={(e) => {
                localStorage.clear()
                navigate("/");
                e.preventDefault();
                dispatch({ type: "OpenALogin" })
              }}
            >
              <Typography style={{ fontFamily: 'Poppins, sans-serif' }} ><i class="fa-solid fa-arrow-right-from-bracket px-2"></i> Logout</Typography>
            </AccordionSummary>


          </Drawer >

        </Box >
      </div > */}

    </>
  )
}
export default Sidebar;