import './CategoryImage.css'
import { useState, useEffect } from 'react'

function CategoryImage() {


    const [AddCategory, setAddCategory] = useState("")
    const [AddCategoryFlag, setAddCategoryFlag] = useState(false)



    const [addproductCategory, setaddproductCategoryl] = useState([])




    const [CategoryId, setCategoryId] = useState('-1')


    useEffect(() => {
        var Url = window.API_PREFIX + 'products/get_category'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                { "Token": localStorage.getItem("Token") }),
        }).then(resp => resp.json()).then(data => {
            console.log(data)
            if (data['status'] == "1") {
                setaddproductCategoryl([...data["AllCategory"]])
                setSelectedOptionaddproductCategory(data["AllCategory"][0]["CategoryName"])
                setcurrentImg(data['AllCategory'][0]['CategoryImg'])

            }
        })
    }, [])

    const [selectedOptionaddproductCategory, setSelectedOptionaddproductCategory] = useState();
    const [currentImg, setcurrentImg] = useState('')
    return (

        <div className='CategoryImage_main_division'>

            <div className='CategoryImage_title'>Category Image</div>

            <div className='addproduct_category_text_field'>
                <div className='addproduct_shipping_weight_title'>Category</div>
                <div class="dropdown">
                    <button class="btn addproduct_detail_save_order_dropdown dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"
                    // onClick={() => {
                    //     var Url = window.API_PREFIX + 'products/get_category'
                    //     fetch(Url, {
                    //         method: 'POST',
                    //         headers: {
                    //             'Content-type': 'application/json',
                    //         },
                    //         body: JSON.stringify(
                    //             { "Token": localStorage.getItem("Token") }),
                    //     }).then(resp => resp.json()).then(data => {
                    //         console.log(data)
                    //         if (data['status'] == "1") {
                    //             setaddproductCategoryl([...data["AllCategory"]])
                    //             setSelectedOptionaddproductCategory(data["AllCategory"][0]["CategoryName"])
                    //             setcurrentImg(data['AllCategory'][0]['CategoryImg'])
                    //         }
                    //     })
                    // }}
                    >
                        {selectedOptionaddproductCategory}
                    </button>
                    <ul class="dropdown-menu">
                        {
                            addproductCategory.map((item) => {
                                return (
                                    <li><a className={selectedOptionaddproductCategory === item.CategoryName ? "bgColorRed dropdown-item order_detail_save_order_dropdown_menu" : "dropdown-item order_detail_save_order_dropdown_menu"}
                                        onClick={() => {
                                            console.log(item.CategoryImg)
                                            setSelectedOptionaddproductCategory(item.CategoryName)
                                            setCategoryId(item.CategoryId)
                                            setcurrentImg(item.CategoryImg)





                                        }
                                        }

                                    >{item.CategoryName}</a></li>
                                )
                            }
                            )
                        }
                    </ul>
                </div>
                {!AddCategoryFlag ? <div className='addproduct_add_icon'
                    onClick={() => {
                        setAddCategoryFlag(true)
                    }}
                ><i class="fa-solid fa-circle-plus"></i></div> :
                    <><input type="text" class="form-control addproductcategoryformControl" placeholder=""

                        onChange={(e) => {
                            setAddCategory(e.target.value)

                        }}
                    ></input>
                        <button className='addproduct_category_add_btn'
                            onClick={() => {
                                if (AddCategory.length != 0) {
                                    var Url = window.API_PREFIX + 'products/add_category'
                                    fetch(Url, {
                                        method: 'POST',
                                        headers: {
                                            'Content-type': 'application/json',
                                        },
                                        body: JSON.stringify(
                                            { "Token": localStorage.getItem("Token"), "CategoryName": AddCategory }),
                                    }).then(resp => resp.json()).then(data => {
                                        console.log(data)
                                        if (data['status'] == "1") {
                                            setAddCategory("")
                                            setAddCategoryFlag(false)
                                        }
                                    })

                                }
                            }}
                        >ADD</button>
                        <button className='addproduct_category_add_btn'
                            onClick={() => {
                                setAddCategoryFlag(false)
                            }}
                        >CLEAR</button></>}
            </div>

            <img style={{width:'40%'}} src={currentImg}
            />
            <h5 style={{wordBreak:"break-word", marginTop:'5px', width:'40%'}}>{currentImg}</h5>


            <input
                style={{ "cursor": "pointer" }}
                type="file"
                name="myImage"
                accept='image/png/jpeg'
                onChange={(event) => {

                    // setSelectedImage(event.target.files[0]);

                    var uploadData = new FormData();
                    // uploadData.append("Token", localStorage.getItem("Token"));
                    // uploadData.append("FirstName", FirstName);
                    // uploadData.append("LastName", LastName);
                    // uploadData.append("Email", Email);
                    // uploadData.append("PhoneNumber", MobileNumber);
                    uploadData.append("CategoryImg", event.target.files[0]);
                    uploadData.append('CategoryId', CategoryId)


                    var url = window.API_PREFIX + "products/add_category_image";


                    fetch(url, {
                        method: 'POST',
                        body: uploadData
                    }).then(resp => resp.json()).then(data => {

                        if (data['status'] == "1") {
                            setcurrentImg(data["ImgAddress"])

                            window.location.reload()
                            // setresponseData({...data["detail"]})

                        }
                    })
                }}
            />



        </div>
    )
}





export default CategoryImage;