import { useState,useEffect } from 'react'
import './Promocode.css'
import { Table } from 'antd';
import 'antd/dist/antd.css';
import '../AdminBase.css';
import { useNavigate } from 'react-router-dom';
import {EditFilled, DeleteFilled } from '@ant-design/icons';
import { Navigate,useParams } from 'react-router-dom';
import { Switch } from 'antd';

function Promocode() {

    let navigate = useNavigate()
    useEffect(() => {

        var Url = window.API_PREFIX + 'admin/view_allcoupons'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                {
                    "Token": localStorage.getItem("Token"),


                }),

        }).then(resp => resp.json()).then(data => {
            console.log(data)
            if (data['status'] === "1") {
                const couponData = [...data['all_coupon']]

                const dataWithSrNo = couponData.map((item, index) => ({
                    ...item,
                    srNo: couponData.length - index,
                  }));
                setAllCouponData(dataWithSrNo)
     

            }
        })},[])

    const Couponcolumn = [
        {
            title: 'Sr. No',
            dataIndex: 'srNo',
            render(text, record, index) {
                console.log("record", record)
                return {
                    props: {
                        style: { fontSize: "16px", textAlign: "center", color: '#483a15' }
                    },
                    children: <div style={{ fontWeight: '600' }}  >{record.srNo}</div>
                }
            }
        },
        {
            title: 'Code',
            dataIndex: 'Name',
            render(text) {
                return {
                    props: {
                        style: { fontSize: "16px", textAlign: "center" }
                    },
                    children: <div>{text}</div>
                }
            }
        },
        {
            title: 'Coupon Type',
            dataIndex: 'DiscountType',
            render(text,record) {
                return {
                    props: {
                        style: { fontSize: "16px", textAlign: "center" }
                    },
                    children: <div>{!text?"FLAT":"Percentage-"+record.Percentage}</div>
                }
            }
        },
        {
            title: 'Coupon Amount',
            dataIndex: 'CoupenValue',
            render(text) {
                return {
                    props: {
                        style: { fontSize: "16px", textAlign: "center" }
                    },
                    children: <div>{text}</div>
                }
            }
        },
        {
            title: 'Description',
            dataIndex: 'Description',
            render(text, record) {
                return {
                    props: {
                        style: { color: "#00ab41", fontSize: "16px" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        // {
        //     title: 'Product IDs',
        //     dataIndex: 'ProductId',
        //     render(text) {
        //         return {
        //             props: {
        //                 style: { fontSize: "16px", textAlign: "center" }
        //             },
        //             children: <div>{text}</div>
        //         }
        //     }
        // },
        // {
        //     title: 'Usage/Limit',
        //     dataIndex: 'Usage',
        //     render(text,record) {
        //         return {
        //             props: {
        //                 style: { fontSize: "16px", textAlign: "center" }
        //             },
        //             children: <div>{record['']}</div>
        //         }
        //     }
        // },
        
        {
            title: 'Created Date',
            dataIndex: 'CreatedOn',
            render(text) {
                return {
                    props: {
                        style: { fontSize: "16px", textAlign: "center" }
                    },
                    children: <div>{text}</div>
                }
            }
        },
        {
            title: 'Expiry Date',
            dataIndex: 'ExpiryDate',
            render(text) {
                return {
                    props: {
                        style: { fontSize: "16px", textAlign: "center" }
                    },
                    children: <div>{text}</div>
                }
            }
        },
        {
            title: 'Edit',
            dataIndex: 'edit',
            render: (text,record,index) => <EditFilled style={{ color: "#00ab41", fontSize: "18px", display: "flex", justifyContent: "center"}}
            onClick={() =>{ navigate("/editpromocode/"+record.id) }}/>

        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            render: (text,record,index) => <DeleteFilled style={{color:"#e11f48", fontSize: "18px", display: "flex", justifyContent: "center"}} 
            onClick={(e) =>{

                var Url = window.API_PREFIX + 'admin/delete_coupon'
                fetch(Url, {
                    method: 'POST',
                    headers: {
                        'Content-type': 'application/json',
                    },
                    body: JSON.stringify(
                        {
                            "Token": localStorage.getItem("Token"),
                            "id":record.id
        
        
                        }),
        
                }).then(resp => resp.json()).then(data => {
                    console.log(data)
                    if (data['status'] === "1") {
                        var tmp = AllCouponData
                        tmp.splice(index, 1)
                        setAllCouponData([...tmp])
             
        
                    }
                })

             

            }}
            
            
            
            />

        },
        {
            title: 'Enable/Disable',
            dataIndex:'CoupenAvailability',
            render: (text,record,index) => <Switch style={{ color: "#e11f48",cursor: "pointer", fontSize: "18px", display: "flex", justifyContent: "center" }}
            checked = {text}
            onClick={(e) =>{

                var Url = window.API_PREFIX + 'admin/edit_coupon_status'
                fetch(Url, {
                    method: 'POST',
                    headers: {
                        'Content-type': 'application/json',
                    },
                    body: JSON.stringify(
                        {
                            "Token": localStorage.getItem("Token"),
                            'promocodeId':record.id
        
        
                        }),
        
                }).then(resp => resp.json()).then(data => {
                    console.log(data)
                    if (data['status'] === "1") {
                        var tmp = AllCouponData
                        tmp[index]['CoupenAvailability'] = !tmp[index]['CoupenAvailability']
                        setAllCouponData([...tmp])
             
        
                    }
                })

             

            }}
            
            />
        },
   
     
    ];

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading, setLoading] = useState(false);
    const [AllCouponData,setAllCouponData] = useState([]);

    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    return (
        <div className='promocode_main_division'>
            <div className='promocode_main_sub_division'>
            <div className='container-fuild'>
                <div className='row'>
                    <div className='col d-flex'>
                        <h2 className='promocode_title'>Promocode</h2>
                        <button type="button" class="btn promocode_addnew_button"
                                onClick={() => {
                                    navigate('/addpromocode')
                                }}
                            >Add New</button>
                    </div>

                    {/* <div className='col d-flex justify-content-end'>
                        <input class="form-control promocode_search_feild me-2" type="search" placeholder="Search" aria-label="Search" />
                        <button class="btn promocode_search_button" type="submit">Search Products</button>
                    </div> */}
                </div>
            </div>

            <div className="col promocode_apds_division">
                <p className='active_promocode_apds '>All<span>({AllCouponData.length})</span></p>

            </div>
            <div
                style={{
                    marginBottom: 16,
                }}>
                <span className='all_products_data_table'
                    style={{
                        marginLeft: 8,
                    }}
                >
                    {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
                </span>
            </div>
            <div className='w-100 allproduct_table_number'>
                <Table className='all_products_tables' rowSelection={rowSelection} columns={Couponcolumn} dataSource={AllCouponData} />
            </div>
            </div>
        </div>
    )
}
export default Promocode;