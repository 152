
import './AddProduct.css';
import './ImgSelector.css'
// import '../../ckeditor/ckeditor.js'

import React, { useEffect } from 'react';
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import Editor from '@ckeditor/ckeditor5-react';
import { useNavigate } from 'react-router-dom';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useState } from 'react';
// import { Height } from '@mui/icons-material';
import Popup from "reactjs-popup";
// import Paragraph from 'antd/lib/skeleton/Paragraph';
import { EditFilled } from '@ant-design/icons';


// import Editor from 'ckeditor5-custom-build/build/ckeditor';
// import { CKEditor } from '@ckeditor/ckeditor5-react';


import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { Button, Popover } from "antd";
import { Form, Input, InputNumber, Table, Typography, Popconfirm } from 'antd';





const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}) => {
    const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                            required: false,
                            message: `Please Input ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};




function AdminPanel() {



    const ImagePopStyle = {
        backgroundColor: "white",
        boxshadow: "0 0 20px rgba(255, 255, 255, 0.4)",
        borderradius: "5px",
        padding: "30px",
        width: "100%",
        margin: "160px 450px",
        height: '700px',
        overflow: 'scroll'
    };


    let navigate = useNavigate()
    const [ImgSelectPop, setImgSelectPop] = useState(false)
    const [AllImgData, setAllImgData] = useState([])
    const [ImgAddress, setImgAddress] = useState("")


    const [MultiImgId, setMultiImgId] = useState([])
    const [MultiImgAddress, setMultiImgAddress] = useState([])



    const [MImgSelectPop, setMImgSelectPop] = useState(false)
    const [AllMImgData, setAllMImgData] = useState([])





    const [showtitleitem, setshowtitleitem] = useState(false)
    const [ProductName, setProductName] = useState("")
    const [IsOffer, setIsOffer] = useState(false)
    const [ProductBrand, setProductBrand] = useState('')
    const [ShortDes, setShortDes] = useState('')
    const [LongDes, setLongDes] = useState('')
    const [titlelist, settitlelist] = useState(["Texable", "Shipping Only", "None"])
    const [titlename, settitlename] = useState("Texable")
    const [SalePriceDateStart, setSalePriceDateStart] = useState("")
    const [SalePriceDateEnd, setSalePriceDateEnd] = useState("")
    const [RegularPrice, setRegularPrice] = useState(0)
    const [SalePrice, setSalePrice] = useState(0)
    const [PriceScheduler, setPriceScheduler] = useState(false)
    const [ActiveProductTab, setActiveProductTab] = useState([true, false, false, false, false, false])
    const [SKU, setSKU] = useState(0)
    const [Weight, setWeight] = useState(0)
    const [Length, setLenght] = useState(0)
    const [width, setwidth] = useState(0)
    const [height, setheight] = useState(0)
    const [Tax, setTax] = useState(5)
    const [Upsales, setUpsales] = useState(0)
    const [crosssales, setcrosssales] = useState()
    const [AddCategory, setAddCategory] = useState("")
    const [AddCategoryFlag, setAddCategoryFlag] = useState(false)
    const [AddSubCategory, setAddSubCategory] = useState("")
    const [AddSubCategoryFlag, setAddSubCategoryFlag] = useState(false)
    const [AddSubSection, setAddSubSection] = useState("")
    const [AddSubSectionFlag, setAddSubSectionFlag] = useState(false)
    const [MenuOrder, setMenuOrder] = useState(0)
    const [StockStatus, setStockStatus] = useState("In_Stock")
    const [Stocknumber, setStocknumber] = useState(1)

    const [MetaTitle, setMetaTitle] = useState("")
    const [MetaKeyword, setMetaKeyword] = useState("")
    const [MetaDescription, setMetaDescription] = useState("")

    const [VariantData, setVariantData] = useState([])

    const [SubCategoryWarn, setSubCategoryWarn] = useState(false)
    const [SubSectionWarn, setSubSectionWarn] = useState(false)
    const [addproductCategory, setaddproductCategoryl] = useState([])
    const [addproductSubSection, setaddproductSubSectionl] = useState([])
    const [addproductSubCategory, setaddproductSubCategoryl] = useState([])
    const [parmalink, setparmalink] = useState('')

    const [selectedImage, setSelectedImage] = useState(null);
    const [ImgId, setImgId] = useState("-1");


    const [CategoryId, setCategoryId] = useState('-1')
    const [SubCategoryId, setSubCategoryId] = useState('-1')
    const [SubSectionId, setSubSectionId] = useState('-1')


    const [Totalquantity, setTotalquantity] = useState("")



    function PublishProduct(Is_Published) {
        if (ImgId != "-1" && CategoryId != "-1" && SubCategoryId != "-1" && VariantData.length != 0) {
            var Url = window.API_PREFIX + 'products/add_product'
            fetch(Url, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify(
                    {
                        "Token": localStorage.getItem("Token"),
                        "SKU": SKU,
                        "Tax": Tax,
                        "Product_Name": ProductName,
                        "ShortDescription": ShortDes,
                        "LongDescription": LongDes,
                        // "Original_Price": RegularPrice,
                        // "Discount_Price": SalePrice,
                        // "StartDate_EndDate": SalePriceDateStart + "_" + SalePriceDateEnd,
                        // "Menu_Order": MenuOrder,
                        "Sub_Category": SubCategoryId,
                        "Category": CategoryId,
                        // "Sub_Section": SubSectionId,
                        "Is_Published": Is_Published,
                        // "Product_Stock": Stocknumber,
                        // "StockStatus": StockStatus,
                        // "Weight": Weight,
                        // "Height": height,
                        // "Length": Length,
                        // "Width": width,
                        "Product_Img": ImgId,
                        // "IsOffer": IsOffer,

                        "Product_Brand": ProductBrand,
                        "ProductMultiImg": MultiImgId,
                        "Varient": VariantData,


                    }),
            }).then(resp => resp.json()).then(data => {
                console.log(data)
                if (data['status'] == "1") {
                    // navigate(`/specification/${data["ProductId"]}`)
                    navigate(`/allproduct`)


                }
            })
        }
    }

    useEffect(() => {
        var Url = window.API_PREFIX + 'products/get_category'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                { "Token": localStorage.getItem("Token") }),
        }).then(resp => resp.json()).then(data => {
            console.log(data)
            if (data['status'] == "1") {
                setaddproductCategoryl([...data["AllCategory"]])
                setSelectedOptionaddproductCategory(data["AllCategory"][0]["CategoryName"])
            }
        })
    }, [])

    const [selectedOptionaddproductCategory, setSelectedOptionaddproductCategory] = useState();


    const [selectedOptionaddproductSubCategory, setSelectedOptionaddproductSubCategory] = useState();
    const [selectedOptionaddproductSubSection, setSelectedOptionaddproductSubSection] = useState();



    const [open, setOpen] = useState(false);

    const hide = () => {
        setOpen(false);
    };

    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };


    const [quantitybundle, setquantitybundle] = useState('');
    const [originalprice, setoriginalprice] = useState();
    const [sellprice, setsellprice] = useState();

    const handlesetoriginalprice = (event) => {
        const rawInputValue = event.target.value;
        const cleanedInputValue = rawInputValue.replace(/^0+/, ''); // Remove leading zeros
        setoriginalprice(cleanedInputValue);
    };

    const handlesetsellprice = (event) => {
        const rawInputValue = event.target.value;
        const cleanedInputValue = rawInputValue.replace(/^0+/, ''); // Remove leading zeros
        setsellprice(cleanedInputValue);
    };


    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');

    const isEditing = (record) => record.key === editingKey;

    const edit = (record) => {
        form.setFieldsValue({
            quantitybundle: record.quantitybundle,
            originalprice: record.originalprice,
            sellprice: record.sellprice,

            ...record,
        });
        setEditingKey(record.key);
    };

    const cancel = () => {
        setEditingKey('');
    };


    const save = async (key) => {
        try {
            const row = await form.validateFields();
            const newData = [...VariantData];
            const index = newData.findIndex((item) => key === item.key);
            var Url = window.API_PREFIX + 'products/edit_attribute'
            //   fetch(Url, {
            //       method: 'POST',
            //       headers: {
            //           'Content-type': 'application/json',
            //       },
            //       body: JSON.stringify(
            //           {
            //               "Token":localStorage.getItem("Token"),
            //               "AttributeId":newData[index].key,
            //               "Product_Id":PID,
            //               "Heading":row.Heading,
            //               "AttributeKey":row.Spec_Key,
            //               "AttributeValue":row.Spec_Value,
            //           }
            //       ),
            //   }).then(resp => resp.json()).then(Revdata => {
            //       console.log(Revdata)
            //       if (Revdata['status'] === "1") 
            //       {
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...row });
                setVariantData(newData);
                setEditingKey('');
            } else {
                newData.push(row);
                setVariantData(newData);
                setEditingKey('');
            }
            // }})
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };



    const content = (
        <div>
            <div>
                <span>Quantity Bundle</span>
                <Input className='popup_input' placeholder="Quantity Bundle" value={quantitybundle} onChange={(e) => {
                    setquantitybundle(e.target.value)

                }} />
            </div>
            <div className='mt-2'>
                <span>Original Price</span>
                <div className='d-flex'>
                    <Input className='popup_input ' type='number' placeholder="Original Price" value={originalprice}
                        step="0.1"
                        min="0"
                        max="20000"
                        onChange={handlesetoriginalprice} />
                </div>

            </div>
            <div className='mt-2'>
                <span>Sell Price</span>
                <div className='d-flex'>
                    <Input className='popup_input ' type='number' placeholder="Sell Price" value={sellprice}
                        step="0.1"
                        min="0"
                        max="20000"
                        onChange={handlesetsellprice} />
                </div>

            </div>
            <div className='d-flex justify-content-between mt-3'>
                <a onClick={hide} style={{ color: '#483a15' }}>Close</a>
                <a onClick={() => {
                    if (quantitybundle.length !== 0 && originalprice != 0) {
                        let newIndex = 1
                        if (VariantData.length > 0) {
                            newIndex = VariantData[VariantData.length - 1]['key'] + 1
                        }

                        setVariantData([...VariantData, {
                            key: newIndex,
                            quantitybundle: quantitybundle,
                            BuyPrice: originalprice,
                            SalePrice: sellprice,
                            IsOffer: false,
                        }]);

                        setquantitybundle("")
                        setoriginalprice("")
                        setsellprice("")


                        setOpen(false);
                    }

                    else {
                        alert("Can not add blank Quantity and price")
                    }


                }} style={{ color: '#483a15' }}>Ok</a>
            </div>

        </div>
    );

    const columns = [
        {
            title: 'Quantity Bundle',
            dataIndex: 'quantitybundle',
            editable: true,

            render(text) {
                return {
                    children: <div>{text}</div>
                }
            }
        },
        {
            title: 'Original Price',
            dataIndex: 'BuyPrice',
            editable: true,

            render(text) {
                return {
                    children: <div>{text}</div>
                }
            }
        },
        {
            title: 'Sell Price',
            dataIndex: 'SalePrice',
            editable: true,

            render(text) {
                return {
                    children: <div>{text}</div>
                }
            }
        },
        {
            title: 'Is Offer',
            dataIndex: "IsOffer",
            width: '10%',
            editable: false,
            render: (_, record, index) => {

                return (<input class="form-check-input" type="checkbox" name="inlineCheckOptions" value='something' checked={record['IsOffer']}
                    onClick={(e) => {
                        //     var Url = window.API_PREFIX + 'products/edit_attributeAsFilter'
                        //     console.log(record.key)
                        //     fetch(Url, {
                        //         method: 'POST',
                        //         headers: {
                        //             'Content-type': 'application/json',
                        //         },
                        //         body: JSON.stringify(
                        //             {
                        //                 "Token":localStorage.getItem("Token"),
                        //                 "AttributeId":record.key,
                        //                 "AsFilter":!record['AsFilter']
                        //             }
                        //         ),
                        //     }).then(resp => resp.json()).then(Revdata => {
                        //         console.log(Revdata)
                        //         if (Revdata['status'] === "1") 
                        //         {  
                        var tmpAttri = VariantData
                        tmpAttri[index]['IsOffer'] = !tmpAttri[index]['IsOffer']
                        setVariantData([...tmpAttri])

                        //         }})

                    }}
                ></input>)
            }
        },
        {
            title: 'Edit',
            dataIndex: 'Edit',
            render(_, record) {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Typography.Link
                            onClick={() => {

                                save(record.key)






                            }}
                            style={{
                                marginRight: 8,
                            }}
                        >
                            Save
                        </Typography.Link>
                        <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                            <a>Cancel</a>
                        </Popconfirm>
                    </span>
                ) : (
                    <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                        <EditFilled style={{ color: "#00ab41", fontSize: "18px", display: "flex", justifyContent: "center" }} />
                        {/* Edit */}
                    </Typography.Link>
                );
            }
        },
        {
            title: 'Delete',
            dataIndex: 'Delete',
            render(text, record, index) {
                return {
                    children: <div

                        onClick={() => {
                            let updatedArray = [...VariantData];

                            updatedArray.splice(index, 1);

                            setVariantData(updatedArray);

                        }}


                    ><i class="fa-solid fa-trash" style={{ color: '#e11f48', cursor: 'pointer' }}></i>


                    </div>
                }
            }
        },
    ];
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex,
                //  === 'age' ? 'number' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    return (
        <>
            <div className='addproduct_main_sub_division'>
                <div className='addproduct_main_division'>
                    <div className='addproduct_add_new_product_title'>Add New Product</div>

                    <div className='addproduct_sub_main_division'>
                        <div className='addproduct_add_new_product_division'>
                            <div className='d-flex justify-content-between'>
                                <input type="text" class="addproductformControl" placeholder="Product Name"
                                    onChange={(e) => {
                                        setProductName(e.target.value)
                                    }} />
                                <input type="text" class="addproductformControl" placeholder="Product Brand Name"
                                    onChange={(e) => {
                                        setProductBrand(e.target.value)
                                    }} />
                            </div>


                            {/* <div className='addproduct_permalink_breadcrums'>
                                <div className='addproduct_permalink'>Permalink:</div>
                                <div className='addproduct_link'><u>https://arihantelect/product_/</u>
                                <input class="addproduct_link" type='text' value={parmalink}
                                onChange={(e)=>{
                                    setparmalink(e.target.value)
                                }}
                                ></input></div>

                            </div> */}

                            <div className="addproduct_text_editor_App">
                                <div className='addproduct_long_discription'>Product short discription</div>
                                {/* <CKEditor
                                    editor={Editor}

                                    data="<p></p>"
                                    onReady={editor => {
                                        console.log('Editor is ready to use!', editor);
                                    }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        // console.log( { event, editor, data } );
                                        setShortDes(data)
                                        // console.log(ShortDes)
                                    }}
                                    onBlur={(event, editor) => {
                                        console.log('Blur.', editor);
                                    }}
                                    onFocus={(event, editor) => {
                                        console.log('Focus.', editor);
                                    }}


                                /> */}
                                <CKEditor
                                    editor={ClassicEditor}

                                    data="<p></p>"
                                    onReady={editor => {
                                        console.log('Editor is ready to use!', editor);
                                    }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        // console.log( { event, editor, data } );
                                        setShortDes(data)
                                        // console.log(ShortDes)
                                    }}
                                    onBlur={(event, editor) => {
                                        console.log('Blur.', editor);
                                    }}
                                    onFocus={(event, editor) => {
                                        console.log('Focus.', editor);
                                    }}


                                />


                            </div>

                            <div className='addproduct_product_data_main_division'>
                                <div className='addproduct_product_data'>
                                    <div className='addproduct_product_data_title'>Product Data</div>
                                </div>

                                <div className='addproduct_category_list_details'>
                                    <div className='addproduct_category_list'>
                                        <div className={ActiveProductTab[0] ? 'active_addproduct_category' : 'addproduct_category'}
                                            onClick={() => {
                                                setActiveProductTab([true, false, false, false, false, false])
                                            }

                                            }
                                        ><i class="fa-solid fa-wrench"></i> Category</div>
                                        {/* <div className={ActiveProductTab[1] ? 'active_addproduct_category' : 'addproduct_category'}
                                            onClick={() => {
                                                setActiveProductTab([false, true, false, false, false, false, false])
                                            }

                                            }
                                        ><i class="fa-solid fa-wrench"></i> General</div>
                                        <div className={ActiveProductTab[2] ? 'active_addproduct_category' : 'addproduct_category'}
                                            onClick={() => {
                                                setActiveProductTab([false, false, true, false, false, false, false])
                                            }}
                                        ><i class="fa-solid fa-briefcase"></i> Inventory</div>

                                        <div className={ActiveProductTab[3] ? 'active_addproduct_category' : 'addproduct_category'}
                                            onClick={() => {
                                                setActiveProductTab([false, false, false, true, false, false, false])
                                            }}
                                        ><i class="fa-solid fa-link"></i>Meta Tag</div>
                                        <div className={ActiveProductTab[4] ? 'active_addproduct_category' : 'addproduct_category'}
                                            onClick={() => {
                                                setActiveProductTab([false, false, false, false, true, false, false])
                                            }}><i class="fa-solid fa-truck-fast"></i> Shipping</div>
                                        <div className={ActiveProductTab[5] ? 'active_addproduct_category' : 'addproduct_category'}
                                            onClick={() => {
                                                setActiveProductTab([false, false, false, false, false, true, false])
                                            }}
                                        ><i class="fa-solid fa-link"></i> Linked Product</div> */}
                                        <div className={ActiveProductTab[6] ? 'active_addproduct_category' : 'addproduct_category'}
                                            onClick={() => {
                                                setActiveProductTab([false, false, false, false, false, false, true])
                                                console.log(VariantData)
                                            }}
                                        ><i class="fa-solid fa-bullseye"></i>Quantity</div>
                                        <div className={ActiveProductTab[7] ? 'active_addproduct_category' : 'addproduct_category'}
                                            onClick={() => {
                                                setActiveProductTab([false, false, false, false, false, false, false, true])
                                                console.log(VariantData)
                                            }}
                                        ><i class="fa-solid fa-percent"></i>Tax</div>
                                        <div className={ActiveProductTab[8] ? 'active_addproduct_category' : 'addproduct_category'}
                                            onClick={() => {
                                                setActiveProductTab([false, false, false, false, false, false, false, false, true])
                                                console.log(VariantData)
                                            }}
                                        ><i class="fa-solid fa-percent"></i>total Item Stock</div>
                                    </div>









                                    {ActiveProductTab[0] ? <div className='addprduct_main_category_field'>
                                        <div className='addproduct_category_text_field'>
                                            <div className='addproduct_shipping_weight_title'>Category</div>
                                            <div class="dropdown">
                                                <button class="btn addproduct_detail_save_order_dropdown dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"
                                                    onClick={() => {
                                                        var Url = window.API_PREFIX + 'products/get_category'
                                                        fetch(Url, {
                                                            method: 'POST',
                                                            headers: {
                                                                'Content-type': 'application/json',
                                                            },
                                                            body: JSON.stringify(
                                                                { "Token": localStorage.getItem("Token") }),
                                                        }).then(resp => resp.json()).then(data => {
                                                            console.log(data)
                                                            if (data['status'] == "1") {
                                                                setaddproductCategoryl([...data["AllCategory"]])
                                                                setSelectedOptionaddproductCategory(data["AllCategory"][0]["CategoryName"])
                                                            }
                                                        })
                                                    }}
                                                >
                                                    {selectedOptionaddproductCategory}
                                                </button>
                                                <ul class="dropdown-menu">
                                                    {
                                                        addproductCategory.map((item) => {
                                                            return (
                                                                <li><a className={selectedOptionaddproductCategory === item.CategoryName ? "bgColorRed dropdown-item order_detail_save_order_dropdown_menu" : "dropdown-item order_detail_save_order_dropdown_menu"}
                                                                    onClick={() => {
                                                                        setSelectedOptionaddproductCategory(item.CategoryName)
                                                                        setCategoryId(item.CategoryId)


                                                                        if (CategoryId != "-1") {

                                                                            var Url = window.API_PREFIX + 'products/get_subcategory'
                                                                            fetch(Url, {
                                                                                method: 'POST',
                                                                                headers: {
                                                                                    'Content-type': 'application/json',
                                                                                },
                                                                                body: JSON.stringify(
                                                                                    { "Token": localStorage.getItem("Token"), "Category_Id": CategoryId }),
                                                                            }).then(resp => resp.json()).then(data => {
                                                                                console.log(data)
                                                                                if (data['status'] == "1") {
                                                                                    setaddproductSubCategoryl([...data["AllSubCategory"]])
                                                                                    if (data["AllSubCategory"].length != 0) {
                                                                                        setSelectedOptionaddproductSubCategory(data["AllSubCategory"][0]["SubCategoryName"])
                                                                                        setSubCategoryId(data["AllSubCategory"][0]["SubCategoryId"])
                                                                                    }
                                                                                    else {
                                                                                        setSubCategoryWarn(true)
                                                                                        setSelectedOptionaddproductSubCategory("")
                                                                                        setaddproductSubCategoryl([])
                                                                                    }
                                                                                }
                                                                            })
                                                                        }


                                                                    }
                                                                    }

                                                                >{item.CategoryName}</a></li>
                                                            )
                                                        }
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                            {!AddCategoryFlag ? <div className='d-flex'>
                                                <div className='addproduct_add_icon'
                                                    onClick={() => {
                                                        setAddCategoryFlag(true)
                                                    }}
                                                ><i class="fa-solid fa-circle-plus"></i></div>
                                                <div className='addproduct_add_icon'
                                                    onClick={() => {
                                                        setAddCategoryFlag(true)
                                                    }}
                                                ><i class="fa-solid fa-pen-to-square"></i></div>
                                                <div className='addproduct_add_icon'
                                                    onClick={() => {

                                                    }}
                                                ><i class="fa-solid fa-trash"></i></div>
                                            </div> :
                                                <><input type="text" class="form-control addproductcategoryformControl" placeholder=""

                                                    onChange={(e) => {
                                                        setAddCategory(e.target.value)

                                                    }}
                                                ></input>
                                                    <button className='addproduct_category_add_btn'
                                                        onClick={() => {
                                                            if (AddCategory.length != 0) {
                                                                var Url = window.API_PREFIX + 'products/add_category'
                                                                fetch(Url, {
                                                                    method: 'POST',
                                                                    headers: {
                                                                        'Content-type': 'application/json',
                                                                    },
                                                                    body: JSON.stringify(
                                                                        { "Token": localStorage.getItem("Token"), "CategoryName": AddCategory }),
                                                                }).then(resp => resp.json()).then(data => {
                                                                    console.log(data)
                                                                    if (data['status'] == "1") {
                                                                        setAddCategory("")
                                                                        setAddCategoryFlag(false)
                                                                    }
                                                                })

                                                            }
                                                        }}
                                                    >ADD</button>
                                                    <button className='addproduct_category_add_btn'
                                                        onClick={() => {
                                                            setAddCategoryFlag(false)
                                                        }}
                                                    >CLEAR</button></>}
                                        </div>








                                        <div className='addproduct_shipping_text_field'>
                                            <div className='addproduct_shipping_weight_title2'>SubCategory</div>
                                            <div class="dropdown">
                                                <button class="btn addproduct_detail_save_order_dropdown dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"
                                                    onClick={() => {
                                                        if (CategoryId != "-1") {

                                                            var Url = window.API_PREFIX + 'products/get_subcategory'
                                                            fetch(Url, {
                                                                method: 'POST',
                                                                headers: {
                                                                    'Content-type': 'application/json',
                                                                },
                                                                body: JSON.stringify(
                                                                    { "Token": localStorage.getItem("Token"), "Category_Id": CategoryId }),
                                                            }).then(resp => resp.json()).then(data => {
                                                                console.log(data)
                                                                if (data['status'] == "1") {
                                                                    setaddproductSubCategoryl([...data["AllSubCategory"]])
                                                                    setSelectedOptionaddproductSubCategory(data["AllSubCategory"][0]["SubCategoryName"])
                                                                    setSubCategoryId(data["AllSubCategory"][0]["SubCategoryId"])
                                                                }
                                                            })
                                                        }
                                                    }}
                                                >
                                                    {selectedOptionaddproductSubCategory}
                                                </button>
                                                <ul class="dropdown-menu">
                                                    {
                                                        addproductSubCategory?.map((item) => {
                                                            return (
                                                                <li><a className={selectedOptionaddproductSubCategory === item.SubCategoryName ? "bgColorRed dropdown-item order_detail_save_order_dropdown_menu" : "dropdown-item order_detail_save_order_dropdown_menu"}
                                                                    onClick={() => {
                                                                        setSelectedOptionaddproductSubCategory(item.SubCategoryName)
                                                                        setSubCategoryId(item.SubCategoryId)
                                                                    }
                                                                    }
                                                                >{item.SubCategoryName}</a></li>
                                                            )
                                                        }
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                            {!AddSubCategoryFlag ? <div className='d-flex'>
                                                <div className='addproduct_add_icon'
                                                    onClick={() => {
                                                        setAddSubCategoryFlag(true)
                                                    }}
                                                ><i class="fa-solid fa-circle-plus"></i></div>
                                                <div className='addproduct_add_icon'
                                                    onClick={() => {
                                                        setAddSubCategoryFlag(true)
                                                    }}
                                                ><i class="fa-solid fa-pen-to-square"></i></div>
                                                <div className='addproduct_add_icon'
                                                    onClick={() => {

                                                    }}
                                                ><i class="fa-solid fa-trash"></i></div>

                                            </div>
                                                : <>
                                                    <input type="text" class="form-control addproductcategoryformControl" placeholder=""
                                                        onChange={(e) => {
                                                            setAddSubCategory(e.target.value)
                                                        }}
                                                    ></input>
                                                    <button className='addproduct_category_add_btn'
                                                        onClick={() => {
                                                            if (AddSubCategory.length != 0) {
                                                                var Url = window.API_PREFIX + 'products/add_subcategory'
                                                                fetch(Url, {
                                                                    method: 'POST',
                                                                    headers: {
                                                                        'Content-type': 'application/json',
                                                                    },
                                                                    body: JSON.stringify(
                                                                        { "Token": localStorage.getItem("Token"), "CategoryId": CategoryId, "SubCategoryName": AddSubCategory }),
                                                                }).then(resp => resp.json()).then(data => {
                                                                    console.log(data)
                                                                    if (data['status'] == "1") {
                                                                        setAddSubCategory("")
                                                                        setAddSubCategoryFlag(false)
                                                                        setSubCategoryWarn(false)

                                                                    }
                                                                })

                                                            }
                                                        }}
                                                    >ADD</button>
                                                    <button className='addproduct_category_add_btn'
                                                        onClick={() => {
                                                            setAddSubCategoryFlag(false)
                                                        }}>CLEAR</button></>}
                                        </div>




                                        {/* <div className='addproduct_shipping_text_field'>
                                            <div className='addproduct_shipping_weight_title3'>SubSection</div>
                                            <div class="dropdown">
                                                <button class="btn addproduct_detail_save_order_dropdown dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"
                                                    onClick={() => {
                                                        if (CategoryId != "-1") {

                                                            var Url = window.API_PREFIX + 'products/get_subsection'
                                                            fetch(Url, {
                                                                method: 'POST',
                                                                headers: {
                                                                    'Content-type': 'application/json',
                                                                },
                                                                body: JSON.stringify(
                                                                    { "Token": localStorage.getItem("Token"), "Category_Id": CategoryId }),
                                                            }).then(resp => resp.json()).then(data => {
                                                                console.log(data)
                                                                if (data['status'] == "1") {
                                                                    setaddproductSubSectionl([...data["AllSubSection"]])
                                                                    setSelectedOptionaddproductSubSection(data["AllSubSection"][0]["SubSectionName"])
                                                                    setSubSectionId(data["AllSubSection"][0]["SubSectionId"])
                                                                }
                                                            })
                                                        }
                                                    }}
                                                >
                                                    {selectedOptionaddproductSubSection}
                                                </button>
                                                <ul class="dropdown-menu">
                                                    {
                                                        addproductSubSection?.map((item) => {
                                                            return (
                                                                <li><a className={selectedOptionaddproductSubSection === item.SubSectionName ? "bgColorRed dropdown-item order_detail_save_order_dropdown_menu" : "dropdown-item order_detail_save_order_dropdown_menu"}
                                                                    onClick={() => {
                                                                        setSelectedOptionaddproductSubSection(item.SubSectionName)
                                                                        setSubSectionId(item.SubSectionId)
                                                                    }
                                                                    }
                                                                >{item.SubSectionName}</a></li>
                                                            )
                                                        }
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                            {!AddSubSectionFlag ? <div className='addproduct_add_icon'
                                                onClick={() => {
                                                    setAddSubSectionFlag(true)
                                                }}
                                            ><i class="fa-solid fa-circle-plus"></i></div> : <>
                                                <input type="text" class="form-control addproductcategoryformControl" placeholder=""
                                                    onChange={(e) => {
                                                        setAddSubSection(e.target.value)
                                                    }}
                                                ></input>
                                                <button className='addproduct_category_add_btn'
                                                    onClick={() => {
                                                        if (AddSubSection.length != 0) {
                                                            var Url = window.API_PREFIX + 'products/add_subsection'
                                                            fetch(Url, {
                                                                method: 'POST',
                                                                headers: {
                                                                    'Content-type': 'application/json',
                                                                },
                                                                body: JSON.stringify(
                                                                    { "Token": localStorage.getItem("Token"), "CategoryId": CategoryId, "SubSectionName": AddSubSection }),
                                                            }).then(resp => resp.json()).then(data => {
                                                                console.log(data)
                                                                if (data['status'] == "1") {
                                                                    setAddSubSection("")
                                                                    setAddSubSectionFlag(false)
                                                                    setSubSectionWarn(false)

                                                                }
                                                            })

                                                        }
                                                    }}
                                                >ADD</button>
                                                <button className='addproduct_category_add_btn'
                                                    onClick={() => {
                                                        setAddSubSectionFlag(false)
                                                    }}>CLEAR</button></>}
                                        </div> */}
                                    </div> : null}

                                    {CategoryId === -1 ? <div>Select Category First</div> : null}
                                    {SubCategoryWarn ? <>No SubCategory found,Add SubCategory</> : null}
                                    {SubSectionWarn ? <>No SubSectionfound,Add SubSection</> : null}


                                    {ActiveProductTab[1] ? <div className='addproduct_main_general_field'>
                                        <div className='addproduct_price_tag'>
                                            <div className='addproduct_general_field' >
                                                <label class="addproduct-form-label">Regular price (₹)</label>
                                                <label class="addproduct-form-label">Sale price (₹)</label>
                                                {PriceScheduler ? <label class="addproduct-form-label1">Sale price dates</label> : null}
                                            </div>

                                            <div className='addproduct_general_field1'>
                                                <input type="number" class="addproductregularpriceformControl" placeholder="" value={RegularPrice}
                                                    onChange={(e) => {
                                                        setRegularPrice(e.target.value)
                                                        console.log(RegularPrice)
                                                    }}></input>
                                                <input type="number" class="addproductregularpriceformControl" placeholder="" value={SalePrice}
                                                    onChange={(e) => {
                                                        setSalePrice(e.target.value)
                                                        console.log(SalePrice)
                                                    }}
                                                ></input>
                                                <div className='addproduct_inventory_manage_stock'>Is Offer active</div>
                                                <input class="form-check-input" type="checkbox" name="inlineCheckOptions" value={IsOffer}
                                                    onClick={(e) => {
                                                        console.log(IsOffer)
                                                        setIsOffer(!IsOffer)

                                                    }}
                                                ></input>
                                                <label class="addproduct-inventory-form-check-label">Enable is offer active for discount price</label>


                                                {PriceScheduler ? <>
                                                    <div class="">
                                                        <input type="date" class="addproductregularpriceformControl" placeholder="DD/MM/YYYY" value={SalePriceDateStart}
                                                            onChange={(e) => {
                                                                setSalePriceDateStart(e.target.value)
                                                                console.log(SalePriceDateStart)
                                                            }}
                                                        ></input>
                                                    </div>

                                                    <div class="">
                                                        <input type="date" class="addproductregularpriceformControl" placeholder="From DD/MM/YYYY" value={SalePriceDateEnd}
                                                            onChange={(e) => {
                                                                setSalePriceDateEnd(e.target.value)
                                                                console.log(SalePriceDateEnd)
                                                            }}
                                                        ></input>
                                                    </div></> : <></>}
                                            </div>

                                            <div className='addproduct_schedule_clear'>
                                                <div className='addproduct_schedule'
                                                    onClick={(e) => {
                                                        setPriceScheduler(true)
                                                    }}
                                                ><u>Schedule</u></div>
                                                {PriceScheduler ? <div className='addproduct_clear'
                                                    onClick={(e) => {
                                                        setPriceScheduler(false)
                                                        setSalePriceDateStart("")
                                                        setSalePriceDateEnd("")
                                                    }}
                                                ><u>Clear</u></div> : null}
                                            </div>
                                        </div>

                                        {/* <div className='addproduct_tax_tag'>
                                        <div class="addproduct_general_tax_field">
                                            <label className="addproduct-form-label">Tax Status</label>
                                            <label className="addproduct-form-label">Tax Class</label>
                                        </div>

                                        <div class="">
                                            <select class="addproducttaxformControl" aria-label=".form-select-sm example">
                                                <option selected>Texable</option>
                                                <option value="1">Shipping Only</option>
                                                <option value="2">None</option>
                                            </select>

                                            <select class="addproducttaxformControl" aria-label=".form-select-sm example">
                                                <option selected>Standard</option>
                                                <option value="1">Reduced rate</option>
                                                <option value="2">Zero rate</option>
                                            </select>
                                        </div>
                                    </div> */}
                                    </div> : null
                                    }

                                    {ActiveProductTab[2] ? <div className='addprduct_main_inventory_field'>
                                        <div className='addproduct_inventory_text_field'>
                                            <div className='addproduct_inventory_sku_title'
                                            >SKU</div>
                                            <input type="text" class="addproductinventoryformControl" placeholder="" value={SKU}
                                                onChange={(e) => {
                                                    setSKU(e.target.value)
                                                    console.log(SKU)
                                                }}></input>


                                            <div><i class="fa-solid fa-circle-question"></i></div>
                                        </div>

                                        <div className='addproduct_inventory_text_field'>
                                            <div className='addproduct_inventory_sku_title2'
                                            >Menu Order</div>
                                            <input type="number" class="addproductinventoryformControl" placeholder="" value={MenuOrder}
                                                onChange={(e) => {
                                                    setMenuOrder(e.target.value)

                                                }}></input>


                                            <div><i class="fa-solid fa-circle-question"></i></div>
                                        </div>

                                        {/* <div class="addproduct-inventory-form-check">
                                        <div className='addproduct_inventory_manage_stock'>Manage stock?</div>
                                        <input class="form-check-input" type="checkbox" name="inlineCheckOptions" value="option3"></input>
                                        <label class="addproduct-inventory-form-check-label">Enable stock management at product level</label>
                                    </div> */}

                                        <div className='addproduct_inventory_stock_status_field'>
                                            <div className='addproduct_inventory_stock_status'>Stock status</div>
                                            <select class="addproducttaxformControl" aria-label=".form-select-sm example">
                                                <option selected>In stock</option>
                                                <option value="1">Out of stock</option>
                                                <option value="2">On backorder</option>
                                            </select>
                                        </div>
                                        {/* 
                                    <div class="addproduct-inventory-form-check">
                                        <div className='addproduct_inventory_Sold_individually'>Sold individually</div>
                                        <input class="form-check-input" type="checkbox" name="inlineCheckOptions" value="option3"></input>
                                        <label class="addproduct-inventory-form-check-label">Enable this to only allow one of this item to be bought in a single order</label>
                                    </div> */}

                                    </div> : null}


                                    {ActiveProductTab[3] ? <div className='addprduct_main_shipping_field'>
                                        <div className='addproduct_shipping_text_field'>
                                            <div className='addproduct_shipping_weight_title'>Meta Title</div>
                                            <input type="text" class="addproductshippingformControl" placeholder="" value={MetaTitle}
                                                onChange={(e) => {
                                                    setMetaTitle(e.target.value)
                                                    console.log(MetaTitle)
                                                }}></input>
                                            <div><i class="fa-solid fa-circle-question"></i></div>
                                        </div>
                                        <div className='addproduct_shipping_text_field'>
                                            <div className='addproduct_shipping_weight_title'>Meta Keyword</div>
                                            <input type="text" class="addproductshippingformControl" placeholder="" value={MetaKeyword}
                                                onChange={(e) => {
                                                    setMetaKeyword(e.target.value)
                                                    console.log(MetaKeyword)
                                                }}></input>
                                            <div><i class="fa-solid fa-circle-question"></i></div>
                                        </div>
                                        <div className='addproduct_shipping_text_field'>
                                            <div className='addproduct_shipping_weight_title'>Meta Description</div>
                                            <input type="text" class="addproductshippingformControl" placeholder="" value={MetaDescription}
                                                onChange={(e) => {
                                                    setMetaDescription(e.target.value)
                                                    console.log(MetaDescription)
                                                }}></input>
                                            <div><i class="fa-solid fa-circle-question"></i></div>
                                        </div>


                                        {/* <div className='addproduct_shipping_class_field'>
                                        <div className='addproduct_shipping_class_field_title'>Shipping class</div>
                                        <select class="addproductshippingformControl" aria-label=".form-select-sm example">
                                            <option selected>No shopping class</option>
                                            <option value="1"></option>
                                            <option value="2"></option>
                                        </select>
                                    </div> */}

                                    </div> : null}

                                    {ActiveProductTab[4] ? <div className='addprduct_main_shipping_field'>
                                        <div className='addproduct_shipping_text_field'>
                                            <div className='addproduct_shipping_weight_title'>Weight (kg)</div>
                                            <input type="number" class="addproductshippingformControl" placeholder="" value={Weight}
                                                onChange={(e) => {
                                                    setWeight(e.target.value)
                                                    console.log(Weight)
                                                }}></input>
                                            <div><i class="fa-solid fa-circle-question"></i></div>
                                        </div>

                                        <div className='addproduct_shipping_dimension_text_field'>
                                            <div className='addproduct_shipping_dimension_title'>Dimensions (cm)</div>
                                            <input type="number" class="addproductshippingdimensionformControl" placeholder="Length" value={Length}
                                                onChange={(e) => {
                                                    setLenght(e.target.value)
                                                    console.log(Length)
                                                }}></input>
                                            <input type="number" class="addproductshippingdimensionformControl" placeholder="width" value={width}
                                                onChange={(e) => {
                                                    setwidth(e.target.value)
                                                    console.log(width)
                                                }}></input>
                                            <input type="number" class="addproductshippingdimensionformControl" placeholder="height" value={height}
                                                onChange={(e) => {
                                                    setheight(e.target.value)
                                                    console.log(height)
                                                }}></input>
                                            <div><i class="fa-solid fa-circle-question"></i></div>
                                        </div>

                                        {/* <div className='addproduct_shipping_class_field'>
                                        <div className='addproduct_shipping_class_field_title'>Shipping class</div>
                                        <select class="addproductshippingformControl" aria-label=".form-select-sm example">
                                            <option selected>No shopping class</option>
                                            <option value="1"></option>
                                            <option value="2"></option>
                                        </select>
                                    </div> */}

                                    </div> : null}

                                    {ActiveProductTab[5] ? <div className='addprduct_main_linked_product_field'>
                                        <div className='addproduct_linked_product_text_field'>
                                            <div className='addproduct_linked_product_upsell_title'>Upsales</div>
                                            <input type="text" class="addproductlinkedproductformControl" placeholder="Search for a product.."
                                                onChange={(e) => {
                                                    setUpsales(e.target.value)
                                                    console.log(Upsales)
                                                }}
                                            ></input>
                                            <div><i class="fa-solid fa-circle-question"></i></div>
                                        </div>

                                        <div className='addproduct_shipping_text_field'>
                                            <div className='addproduct_shipping_cross_sells_title'>Cross-sells</div>
                                            <input type="text" class="addproductlinkedproductformControl" placeholder="Search for a product.."
                                                onChange={(e) => {
                                                    setcrosssales(e.target.value)
                                                    console.log(crosssales)
                                                }}></input>
                                            <div><i class="fa-solid fa-circle-question"></i></div>
                                        </div>
                                    </div> : null}

                                    {ActiveProductTab[6] ? <div className='addprduct_main_linked_product_field'>
                                        <Popover
                                            content={content}
                                            // title="ssss"
                                            trigger="click"
                                            open={open}
                                            onOpenChange={handleOpenChange}
                                        >
                                            <Button style={{ background: '#483a15', borderColor: '#483a15' }} type="primary">New variant</Button>
                                        </Popover>

                                        <div >
                                            <Form form={form} component={false}>
                                                <Table className='mt-3'
                                                    rowClassName="editable-row"
                                                    columns={mergedColumns}
                                                    dataSource={VariantData}
                                                    components={{
                                                        body: {
                                                            cell: EditableCell,
                                                        },
                                                    }}
                                                    size="middle" />
                                            </Form>
                                        </div>
                                    </div> : null}


                                    {ActiveProductTab[7] ? <div className='d-flex tax_main_div '>

                                        <div class="dropdown">
                                            <button class="btn dropdown-toggle addproduct_detail_save_order_dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                GST%
                                            </button>
                                            <ul class="dropdown-menu mx-2">
                                                <li><a class="dropdown-item" href="#" onClick={() => {
                                                    setTax(5)
                                                }}>5%</a></li>
                                                <li><a class="dropdown-item" href="#"
                                                    onClick={() => {
                                                        setTax(12)
                                                    }}>12%</a></li>
                                                <li><a class="dropdown-item" href="#"
                                                    onClick={() => {
                                                        setTax(18)
                                                    }}>18%</a></li>
                                                <li><a class="dropdown-item" href="#"
                                                    onClick={() => {
                                                        setTax(28)
                                                    }}>28%</a></li>

                                            </ul>
                                            {Tax}%
                                        </div>



                                    </div>

                                        : null}

                                    {
                                        ActiveProductTab[8] ?
                                            <div>
                                                <input type="number" class="addproductformControl" placeholder=""
                                                    onChange={(e) => {
                                                        setTotalquantity(e.target.value)
                                                    }} />
                                            </div> : null
                                    }
                                </div>
                            </div>

                            {/* <div className="addproduct_text_editor_App">
                                <div className='addproduct_product_data'>
                                    <div className='addproduct_short_discription'>Product long description(Overview)</div>
                                </div>

                                <CKEditor
                                    editor={ClassicEditor}

                                    data="<p></p>"
                                    onReady={editor => {
                                        console.log('Editor is ready to use!', editor);
                                    }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        // console.log( { event, editor, data } );
                                        setLongDes(data)

                                    }}
                                    onBlur={(event, editor) => {
                                        console.log('Blur.', editor);
                                    }}
                                    onFocus={(event, editor) => {
                                        console.log('Focus.', editor);
                                    }}
                                />
                            </div> */}

                            {/* <CKEditor
                                    editor={Editor}

                                    data="<p></p>"
                                    onReady={editor => {
                                        console.log('Editor is ready to use!', editor);
                                    }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        // console.log( { event, editor, data } );
                                        setLongDes(data)

                                    }}
                                    onBlur={(event, editor) => {
                                        console.log('Blur.', editor);
                                    }}
                                    onFocus={(event, editor) => {
                                        console.log('Focus.', editor);
                                    }}
                                /> */}



                        </div>



                        <div className='addproduct_main_Social_division'>


                            {/* <div className='addproduct_main_product_category'>
                            <div className='addproduct_publish_data'>
                                <div className='addproduct_publish_title'>Product catrgory</div>
                            </div>
                            <div className='addproduct_category_main_division_list_title'>
                                <div className='addproduct_category_list_title'>
                                    <div className='addproduct_all_category'>All category</div>
                                    <div className='addproduct_most_used'>Most Used</div>
                                </div>
                                
                                <div className='addproduct_checkbox_button'>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" name="inlineCheckOptions" value="option3"></input>
                                        <label class="addproduct-form-check-label">Uncategorized</label>
                                    </div>

                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" name="inlineCheckOptions" value="option3"></input>
                                        <label class="addproduct-form-check-label">iPhone</label>
                                    </div>

                                    <div className='addproduct_margin_checkbox_label'>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" name="inlineCheckOptions" value="option3"></input>
                                            <label class="addproduct-form-check-label">Tablet</label>
                                        </div>

                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" name="inlineCheckOptions" value="option3"></input>
                                            <label class="addproduct-form-check-label">Buds</label>
                                        </div>
                                    </div>

                                    <div className='addproduct_second_margin_checkbox_label'>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" name="inlineCheckOptions" value="option3"></input>
                                            <label class="addproduct-form-check-label">Tablet</label>
                                        </div>

                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" name="inlineCheckOptions" value="option3"></input>
                                            <label class="addproduct-form-check-label">Buds</label>
                                        </div>

                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" name="inlineCheckOptions" value="option3"></input>
                                            <label class="addproduct-form-check-label">Buds</label>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div> */}

                            {/* <div className='addproduct_main_product_tag'>
                            <div className='addproduct_publish_data'>
                                <div className='addproduct_publish_title'>Product tag</div>
                                
                            </div>
                            <div className='addproduct_text_field'>
                                <div className='addproduct_text_add_button'>
                                    <input type="text" class="addproducttagformControl" placeholder=""></input>
                                    <button type="button" class="addproduct-btn-add">Add</button>
                                </div>
                                <div className='addproduct_set_product_separate_tag'>Separate tags with commas</div>
                                <div className='addproduct_set_product_tag'><u>Choose from the most used tags</u></div>
                            </div>
                        </div> */}

                            <div className='addproduct_main_product_image'>
                                <div className='addproduct_publish_data'>
                                    <div className='addproduct_publish_title'>Product Image</div>

                                </div>

                                <div className='addproduct_set_product_image'
                                >

                                    <Popup
                                        modal

                                        contentStyle={ImagePopStyle}
                                        open={ImgSelectPop}
                                        closeOnDocumentClick
                                        onClose={() => {
                                            setImgSelectPop(false)

                                        }}

                                    >


                                        <div>
                                            <div className='grid-container'>
                                                {AllImgData?.map((eachImg, ImgIndex) => {

                                                    return (<div className='addproduct_image_photo_division'>
                                                        <div>
                                                            <div className='addproduct_image_gallery_division'>
                                                                <div className='addproduct_close_division'
                                                                    onClick={() => {
                                                                        var Url = window.API_PREFIX + 'products/delete_productImg'
                                                                        fetch(Url, {
                                                                            method: 'POST',
                                                                            headers: {
                                                                                'Content-type': 'application/json',
                                                                            },
                                                                            body: JSON.stringify(
                                                                                { "Token": localStorage.getItem("Token"), "ImgId": eachImg["ImgId"] }),
                                                                        }).then(resp => resp.json()).then(data => {
                                                                            console.log(data)
                                                                            if (data['status'] == "1") {
                                                                                var tmp = AllImgData
                                                                                tmp.splice(ImgIndex, 1)
                                                                                console.log("Delete", tmp)
                                                                                setAllImgData([...tmp])

                                                                            }
                                                                        })
                                                                    }}
                                                                ><i class="fa-solid fa-xmark"></i></div>
                                                                <img className='addproduct_galleryimg' src={eachImg['ImgAddress']}
                                                                    onClick={() => {
                                                                        setImgAddress(eachImg['ImgAddress'])
                                                                        setImgId(eachImg['ImgId'])
                                                                        setImgSelectPop(false)
                                                                    }}
                                                                />

                                                            </div>
                                                            <div className='addproduct_image_name'>{eachImg['Name']}</div>
                                                        </div>
                                                    </div>)
                                                })}

                                            </div>
                                        </div>
                                    </Popup>
                                    <u
                                        onClick={() => {

                                            if (!ImgSelectPop) {
                                                var Url = window.API_PREFIX + 'products/get_productImg'
                                                fetch(Url, {
                                                    method: 'POST',
                                                    headers: {
                                                        'Content-type': 'application/json',
                                                    },
                                                    body: JSON.stringify(
                                                        { "Token": localStorage.getItem("Token") }),
                                                }).then(resp => resp.json()).then(data => {
                                                    console.log(data)
                                                    if (data['status'] === "1") {
                                                        setAllImgData([...data["AllImgData"]])
                                                    }
                                                })
                                            }
                                            setImgSelectPop(true)

                                        }}
                                    >Set product image </u>
                                    <div>
                                        <div className='grid-container'>
                                            <div className='addproduct_img_grid_division'>
                                                {ImgAddress != "" ? <img className='addproduct_galleryimg_product_img' src={ImgAddress} /> : null}
                                            </div>
                                        </div>
                                        <input
                                            className='addproduct_inputtag'
                                            style={{ "cursor": "pointer" }}
                                            type="file"
                                            name="myImage"
                                            accept='image/png/jpeg'
                                            onChange={(event) => {

                                                setSelectedImage(event.target.files[0]);

                                                var uploadData = new FormData();
                                                uploadData.append("Token", localStorage.getItem("Token"));
                                                // uploadData.append("FirstName", FirstName);
                                                // uploadData.append("LastName", LastName);
                                                // uploadData.append("Email", Email);
                                                // uploadData.append("PhoneNumber", MobileNumber);
                                                uploadData.append("ProductImg", event.target.files[0]);


                                                var url = window.API_PREFIX + "products/add_productImg";


                                                fetch(url, {
                                                    method: 'POST',
                                                    body: uploadData
                                                }).then(resp => resp.json()).then(data => {

                                                    if (data['status'] == "1") {
                                                        setImgId(data["ImgId"])
                                                        console.log(data["ImgId"])
                                                        setImgAddress(data['ImgAddress'])
                                                        // window.location.reload()
                                                        // setresponseData({...data["detail"]})

                                                    }
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='addproduct_main_product_gallery'>
                                <div className='addproduct_publish_data'>
                                    <div className='addproduct_publish_title'>Product gallery</div>


                                </div>

                                <div className='addproduct_set_product_gallery'

                                >
                                    <Popup
                                        modal

                                        contentStyle={ImagePopStyle}
                                        open={MImgSelectPop}
                                        closeOnDocumentClick
                                        onClose={() => {
                                            setMImgSelectPop(false)

                                        }}

                                    >


                                        <div>
                                            <div className='grid-container'>
                                                {AllMImgData?.map((eachImg, ImgIndex) => {

                                                    return (<div className='addproduct_image_photo_division'>
                                                        <div>
                                                            <div className='addproduct_image_gallery_division'>
                                                                <div className='addproduct_close_division'
                                                                    onClick={() => {
                                                                        var Url = window.API_PREFIX + 'products/delete_productImg'
                                                                        fetch(Url, {
                                                                            method: 'POST',
                                                                            headers: {
                                                                                'Content-type': 'application/json',
                                                                            },
                                                                            body: JSON.stringify(
                                                                                { "Token": localStorage.getItem("Token"), "ImgId": eachImg["ImgId"] }),
                                                                        }).then(resp => resp.json()).then(data => {
                                                                            console.log(data)
                                                                            if (data['status'] == "1") {
                                                                                var tmp = AllMImgData
                                                                                tmp.splice(ImgIndex, 1)
                                                                                console.log("Delete", tmp)
                                                                                setAllMImgData([...tmp])
                                                                                setMultiImgAddress([])
                                                                                setMultiImgId([])

                                                                            }
                                                                        })
                                                                    }}
                                                                ><i class="fa-solid fa-xmark"></i></div>
                                                                <img className='addproduct_galleryimg' src={eachImg['ImgAddress']}
                                                                    onClick={() => {
                                                                        var tmpImgAddress = MultiImgAddress
                                                                        tmpImgAddress.push(eachImg['ImgAddress'])
                                                                        setMultiImgAddress([...tmpImgAddress])
                                                                        var tmpImgId = MultiImgId
                                                                        tmpImgId.push(eachImg['ImgId'])
                                                                        setMultiImgId([...tmpImgId])
                                                                        setMImgSelectPop(false)
                                                                    }}
                                                                />

                                                            </div>
                                                            <div className='addproduct_image_name'>{eachImg['Name']}</div>
                                                        </div>
                                                    </div>)
                                                })}

                                            </div>
                                        </div>
                                    </Popup>

                                    <u
                                        onClick={() => {

                                            if (!MImgSelectPop) {
                                                var Url = window.API_PREFIX + 'products/get_productImg'
                                                fetch(Url, {
                                                    method: 'POST',
                                                    headers: {
                                                        'Content-type': 'application/json',
                                                    },
                                                    body: JSON.stringify(
                                                        { "Token": localStorage.getItem("Token") }),
                                                }).then(resp => resp.json()).then(data => {
                                                    console.log(data)
                                                    if (data['status'] == "1") {
                                                        setAllMImgData([...data["AllImgData"]])
                                                    }
                                                })
                                            }
                                            setMImgSelectPop(true)

                                        }}

                                    >Set product gallery images</u></div>
                                {MultiImgAddress.length != 0 ? MultiImgAddress.map((eachImage, ImgIndex) => {
                                    return (<img className='addproduct_galleryimg_product_img' src={eachImage}
                                        onClick={() => {
                                            var tmpImgAddress = MultiImgAddress
                                            tmpImgAddress.splice(ImgIndex, 1)
                                            setMultiImgAddress([...tmpImgAddress])
                                            var tmpImgId = MultiImgId
                                            tmpImgId.splice(ImgIndex, 1)
                                            setMultiImgId([...tmpImgId])
                                        }}
                                    />)
                                }) : null}
                                <input
                                    className='addproduct_inputtag'
                                    style={{ "cursor": "pointer", marginLeft: "10px" }}
                                    type="file"
                                    accept='image/png/jpeg'

                                    name="myImage"
                                    multiple
                                    onChange={(event) => {

                                        setSelectedImage(event.target.files);

                                        const chosenFiles = Array.prototype.slice.call(event.target.files)
                                        var uploadData = new FormData();

                                        var imgList = []
                                        for (var i = 0; i < event.target.files.length; i++) {
                                            uploadData.append("MultiProductImg" + i, event.target.files[i]);

                                            imgList.push(event.target.files[i])
                                        }



                                        var url = window.API_PREFIX + "products/add_multiproductImg";


                                        fetch(url, {
                                            method: 'POST',
                                            body: uploadData
                                        }).then(resp => resp.json()).then(data => {

                                            if (data['status'] == "1") {
                                                console.log(data['ImgId'])
                                                console.log(data['ImgAddress'])
                                                var tmpImgId = MultiImgId
                                                for (var i = 0; i < data['ImgId'].length; i++) {
                                                    tmpImgId.push(data['ImgId'][i])
                                                }

                                                var tmpImgAdd = MultiImgAddress
                                                for (var j = 0; j < data['ImgAddress'].length; j++) {
                                                    tmpImgAdd.push(data['ImgAddress'][j])
                                                }


                                                setMultiImgId([...tmpImgId])

                                                setMultiImgAddress([...tmpImgAdd])
                                                // window.location.reload()
                                                // setresponseData({...data["detail"]})

                                            }
                                        })
                                    }}
                                />
                            </div>

                            <div className='addproduct_main_publish_division'>

                                <div className='addproduct_publish_details_field'>
                                    <button type="button" class="addproduct_btn_save_draft"
                                        onClick={() => PublishProduct(false)}
                                    >Save Draft</button>
                                </div>

                                <div className='addproduct_last_division_new_draft'>
                                    <button type="button" class="addproduct_btn_publish"
                                        onClick={() => PublishProduct(true)}

                                    >Publish</button>
                                </div>

                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdminPanel;