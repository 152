import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useStateValue } from "../../StateProviders";

function Sidebarthree() {


    const [hover, setHover] = useState(false);
    const [click, setclick] = useState(false);

    const handleMouseEnter = () => {
        setHover(true);
    };

    const handleMouseLeave = () => {
        setHover(false);
        setopen(false)
        setopentwo(false)
        setopenthree(false)
    };

    const barside = {
        width: hover || click ? 240 : 80,
    }


    const location = useLocation();
    const pathname = location.pathname;

    let navigate = useNavigate()



    const [open, setopen] = useState(false);
    const [opentwo, setopentwo] = useState(false);
    const [openthree, setopenthree] = useState(false);

    const [{ ShowAdminLoginPop }, dispatch] = useStateValue();



    return (
        <div>


            <div
                style={barside}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className='sidebar_three' >
                <div>


                    {/* <div style={{ marginBottom: '10px' }}>
            
                        <i class={ click ? "fa-solid fa-xmark fa-2xl ff" : "fa-solid fa-bars fa-2xl ff" } onClick={() => {
                            setclick(!click)
                            setopen(false)
                            setopentwo(false)
                            setopenthree(false)
                        }}></i>

                    </div> */}



                    <li className={pathname === "/dashboard" ? "active" : "non_active"}>
                        <Link to="/dashboard" className='link'>
                            <i className="fa-solid fa-globe fa-xl menu_icon"></i>
                            <span className='span_li'>Dashboard</span>
                        </Link>
                    </li>

                    <li className={pathname === "/allorder" ? "active" : "non_active"}>
                        <Link to="/allorder" className='link'>
                            <i className="fa-solid fa-truck fa-xl menu_icon"></i>
                            <span className='span_li'> Order</span>
                        </Link>
                    </li>


                    <li className='link' onClick={() => {
                        setopen(!open)
                        setopentwo(false)
                        setopenthree(false)
                    }}>
                        <i className="fa-solid fa-box fa-xl menu_icon"></i>
                        <span className='span_li'>Product</span>
                        <i className={`fa-solid ${open ? 'fa-angle-up' : 'fa-angle-down'}`} style={{ color: 'white', marginLeft: '75px' }}></i>
                    </li>

                    {open ?
                        <div className='feature_div'>
                            <div className={pathname === "/addproduct" ? 'ul_element_active' : 'ul_element'}>
                                <Link className='linktitle' to="/addproduct"
                                >- Add Product</Link>
                            </div>
                            <div className={pathname === "/allproduct" ? 'ul_element_active' : 'ul_element'}>
                                <Link className='linktitle' to="/allproduct">- All Product</Link></div>
                        </div>

                        : null
                    }



                    <li className={pathname === "/alluser" ? "active" : "non_active"}>
                        <Link to="/alluser" className='link'>
                            <i className="fa-solid fa-user fa-xl menu_icon"></i>
                            <span className='span_li'>All user</span>
                        </Link>
                    </li>



                    <li className='link' onClick={() => {
                        setopentwo(!opentwo)
                        setopen(false)
                        setopenthree(false)
                    }}>
                        <i className="fa-solid fa-house fa-xl menu_icon"></i>
                        <span className='span_li'>Homepage</span>
                        <i className={`fa-solid ${opentwo ? 'fa-angle-up' : 'fa-angle-down'}`} style={{ color: 'white', marginLeft: '45px' }}></i>
                    </li>

                    {opentwo ?
                        <div className='feature_div'>
                            <div className={pathname === "/slider" ? 'ul_element_active' : 'ul_element'}>
                                <Link className='linktitle' to="/slider"
                                >- Slider</Link>
                            </div>
                            <div className={pathname === "/categoryimage" ? 'ul_element_active' : 'ul_element'}>
                                <Link className='linktitle' to="/categoryimage">- Category Mobile</Link></div>
                        </div>

                        : null
                    }


                    <li className='link' onClick={() => {
                        setopenthree(!openthree)
                        setopentwo(false)
                        setopen(false)
                    }}>
                        <i className="fa-solid fa-percent fa-xl menu_icon"></i>
                        <span className='span_li'>Promocode</span>
                        <i className={`fa-solid ${openthree ? 'fa-angle-up' : 'fa-angle-down'}`} style={{ color: 'white', marginLeft: '51px' }}></i>
                    </li>

                    {openthree ?
                        <div className='feature_div'>
                            <div className={pathname === "/promocode" ? 'ul_element_active' : 'ul_element'}>
                                <Link className='linktitle' to="/promocode"
                                >- Promocode</Link>
                            </div>
                            <div className={pathname === "/addpromocode" ? 'ul_element_active' : 'ul_element'}>
                                <Link className='linktitle' to="/addpromocode">- Add Promocode</Link></div>
                        </div>

                        : null
                    }











                    <li className={pathname === "/addnotifications" ? "active" : "non_active"}>
                        <Link to="/addnotifications" className='link'>
                            <i className="fa-solid fa-bell fa-xl menu_icon"></i>
                            <span className='span_li'>Notifications</span>
                        </Link>
                    </li>

                    <li className={pathname === "/contact-details" ? "active" : "non_active"}>
                        <Link to="/contact-details" className='link'>
                            <i class="fa-solid fa-message fa-xl menu_icon"></i>
                            <span className='span_li'>Messages</span>
                        </Link>
                    </li>



                    <li className="non_active"
                        onClick={(e) => {
                            localStorage.clear()
                            navigate("/");
                            e.preventDefault();
                            dispatch({ type: "OpenALogin" })
                        }}
                    >
                        <Link className='link'>
                            <i className="fa-solid fa-arrow-right-from-bracket  fa-xl menu_icon"></i>
                            <span className='span_li'>Logout</span>
                        </Link>
                    </li>








                </div>

            </div>



        </div >
    )
}

export default Sidebarthree;