import { useState } from "react";
import "./Alluser.css";
import { Table } from "antd";
import { useEffect } from "react";
import "antd/dist/antd.css";
import { EditFilled, DeleteFilled, EyeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Empty } from "antd";
import Loader from "../../Loader";

function Alluser() {
  let navigate = useNavigate();
  const [bulkAction, setBulkAction] = useState([
    {
      dropdownname: "Bulk Action",
    },
    {
      dropdownname: "Delete",
    },
    {
      dropdownname: "Send password reset",
    },
  ]);

  const [changeroleto, setChangeroleto] = useState([
    {
      dropdownname: "Change Role To..",
    },
    {
      dropdownname: "Shop Manager",
    },
    {
      dropdownname: "Customer",
    },
    {
      dropdownname: "Administrator",
    },
  ]);
  const [selectedOptionBulk, setSelectedOptionBulk] = useState(
    bulkAction[0].dropdownname
  );

  const [selectedOption, setSelectedOption] = useState(
    changeroleto[0].dropdownname
  );

  const [AllUserData, setAllUserData] = useState([]);
  const [NonSearchUserData, setNonSearchUserData] = useState([]);
  // const [UserProduct,setUserProduct] = useState("");

  useEffect(() => {
    var Url = window.API_PREFIX + "admin/all_user";
    setLoading(true);
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      // body: JSON.stringify(),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "1") {
          let userData = [...data["users"]]
          const dataWithSrNo = userData.map((item, index) => ({
            ...item,
            srNo: userData.length - index,
          }));
          setAllUserData(dataWithSrNo);
          setNonSearchUserData(dataWithSrNo);
          // setUserProduct(data["Total_Products"])
          setLoading(false);
        }
      });
  }, []);

  const columns = [
    {
      title: 'Sr. No',
      dataIndex: 'srNo',
      render(text, record, index) {
          return {
              props: {
                  style: { fontSize: "16px", textAlign: "center", color: '#483a15' }
              },
              children: <div style={{ fontWeight: '600' }}  >{record.srNo}</div>
          }
      }
    },
    {
      title: "UserName",
      dataIndex: "Name",
      render(text) {
        return {
          props: {
            style: { fontSize: "16px", textAlign: "center" },
          },
          children: <div>{text}</div>,
        };
      },
    },

    {
      title: "Email",
      dataIndex: "Email",
      render(text) {
        return {
          props: {
            style: { fontSize: "16px", textAlign: "center" },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Number",
      dataIndex: "MobileNo",
      render(text) {
        return {
          props: {
            style: { fontSize: "16px", textAlign: "center" },
          },
          children: <div>{text}</div>,
        };
      },
    },

    {
      title: "Orders Count",
      dataIndex: "Total_Order",
      render(text) {
        return {
          props: {
            style: { fontSize: "16px", textAlign: "center" },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "cart",
      dataIndex: "cart_items",
      render: (text, record, index) => (
        <EyeOutlined
          style={{
            color: "#e11f48",
            cursor: "pointer",
            fontSize: "18px",
            display: "flex",
            justifyContent: "center",
          }}
          onClick={() => {
            navigate(
              "/allUsercart/" + record.id + "&&" + record.Name
            );
          }}
        />
      ),
    },
    {
      title: "Cart_History",
      dataIndex: "cart_items",
      render: (text, record, index) => (
        <EyeOutlined
          style={{
            color: "#e11f48",
            cursor: "pointer",
            fontSize: "18px",
            display: "flex",
            justifyContent: "center",
          }}
          onClick={() => {
            navigate(
              "/allUsercarthistory/" +
              record.id +
              "&&" +
              record.Name
            );
          }}
        />
      ),
    },
    {
      title: "Edit",
      dataIndex: "edit",
      render: (text, record) => (
        <EditFilled
          onClick={() => {
            console.log("record", record)
            navigate(`/edituser/${record.id}`)
          }}
          style={{
            color: "#00ab41",
            fontSize: "18px",
            display: "flex",
            justifyContent: "center",
          }}
        />
      ),
    },
    {
      title: "Delete",
      dataIndex: "Delete",
      render: () => (
        <DeleteFilled
          style={{
            color: "#e11f48",
            fontSize: "18px",
            display: "flex",
            justifyContent: "center",
          }}
        />
      ),
    },
  ];

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  return (
    <div className="alluser_main_division">
      <div className="alluser_main_sub_division">
        <div className="container-fuild">
          <div className="row">
            <div className="col d-flex align-items-center">
              <div className="allusers_title">Users</div>

            </div>
            <div className="col d-flex justify-content-end align-items-center">
              <input
                class="form-control allusers_search_feild me-2"
                type="search"
                placeholder="Search"
                aria-label="Search"
                onChange={(e) => {
                  var newArray = NonSearchUserData.filter(function (el) {
                    return el["Name"]
                      .toLowerCase()
                      .includes(e.target.value.toLocaleLowerCase());
                  });

                  setAllUserData([...newArray]);
                }}
              />
            </div>
          </div>
        </div>
        <div className="w-100 mt-3">
          <Table
            className="all_products_tables"
            rowSelection={rowSelection}
            columns={columns}
            dataSource={AllUserData}
            locale={{
              emptyText: loading ? <Loader /> : <Empty />,
            }}
          />
        </div>
      </div>
    </div>
  );
}
export default Alluser;
