import React from 'react';
import './Dashboard.css';
import { PieChart, Pie, Tooltip, Cell } from "recharts";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';
import { BarChart, Bar, Legend } from 'recharts';





function Dashboard() {

    const data2 = [
        { name: 'Picup Order', value: 400 },
        { name: 'Delivery', value: 300 },
    ];
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];


    const data = [
        {
            name: 'Jan',
            Revenue: 4000,
            amt: 2400,
        },
        {
            name: 'Feb',
            Revenue: 3000,
            amt: 2210,
        },
        {
            name: 'March',
            Revenue: 2000,
            amt: 2290,
        },
        {
            name: 'April',
            Revenue: 2780,
            amt: 2000,
        },
        {
            name: 'May',
            Revenue: 1890,
            pv: 4800,
            amt: 2181,
        },
        {
            name: 'June',
            Revenue: 2390,
            amt: 2500,
        },
        {
            name: 'July',
            Revenue: 3490,
            amt: 2100,
        },
        {
            name: 'Aug',
            Revenue: 3490,
            amt: 2100,
        },
        {
            name: 'sept',
            Revenue: 2900,
            pv: 4300,
            amt: 2100,
        },
        {
            name: 'Oct',
            Revenue: 3490,
            amt: 2100,
        },
        {
            name: 'Nov',
            Revenue: 356,
            amt: 2100,
        },
        {
            name: 'Dec',
            Revenue: 3490,
            amt: 2100,
        },
    ];




    const bardata = [
        {
            name: 'Jan',
            uv: 4000,
            pv: 2400,
            amt: 2400,
        },
        {
            name: 'Feb',
            uv: 3000,
            pv: 1398,
            amt: 2210,
        },
        {
            name: 'Mar',
            uv: 2000,
            pv: 9800,
            amt: 2290,
        },
        {
            name: 'April',
            uv: 2780,
            pv: 3908,
            amt: 2000,
        },
        {
            name: 'May',
            uv: 1890,
            pv: 4800,
            amt: 2181,
        },
        {
            name: 'June',
            uv: 2390,
            pv: 3800,
            amt: 2500,
        },
        {
            name: 'July',
            uv: 3490,
            pv: 4300,
            amt: 2100,
        },
        {
            name: 'Page G',
            uv: 3490,
            pv: 4300,
            amt: 2100,
        },
        {
            name: 'Page G',
            uv: 3490,
            pv: 4300,
            amt: 2100,
        },
        {
            name: 'Page G',
            uv: 3490,
            pv: 4300,
            amt: 2100,
        },
        {
            name: 'Page G',
            uv: 3490,
            pv: 4300,
            amt: 2100,
        },
        {
            name: 'Page G',
            uv: 3490,
            pv: 4300,
            amt: 2100,
        },
    ];





    return (
        <div className='p-3'>
            <h5>Dashboard</h5>
            <div className='row dashboard_first_div p-2 gap-5' >
                <div className='col-md-2 col-lg-2 col-12 first_small_div'>
                    <div>
                        <h4>55</h4>
                        <span>Today-Visitors</span>
                    </div>
                    <div className='icon_back'>
                        <i class="fa-solid fa-user fa-xl"></i>
                    </div>

                </div>
                <div className='col-md-2 col-lg-2 col-12 first_small_div'>
                    <div>
                        <h4>25</h4>
                        <span>Today-Orders</span>
                    </div>

                    <div className='icon_back'>
                        <i class="fa-solid fa-box fa-xl"></i>
                    </div>
                </div>
                <div className='col-md-2 col-lg-2 col-12 first_small_div'>
                    <div>
                        <div className='d-flex font-weight-bold'>
                            <span>₹</span>
                            <h4>2569</h4>
                        </div>

                        <span>Today-Sales</span>
                    </div>


                    <div className='icon_back'>
                        <i class="fa-solid fa-money-bills fa-xl"></i>
                    </div>
                </div>
                <div className='col-md-2 col-lg-2 col-12 first_small_div'>
                    <div>
                        <div className='d-flex font-weight-bold'>
                            <span >₹</span>
                            <h4>5560</h4>
                        </div>
                        <span>Week-Sales</span>
                    </div>
                    <div className='icon_back'>
                        <i class="fa-solid fa-money-bills fa-xl"></i>
                    </div>
                </div>
                <div className='col-md-2 col-lg-2 col-12 first_small_div'>
                    <div>
                        <div className='d-flex font-weight-bold'>
                            <span>₹</span>
                            <h4>105222</h4>
                        </div>
                        <span>Month-Sales</span>
                    </div>
                    <div className='icon_back'>
                        <i class="fa-solid fa-money-bills fa-xl"></i>
                    </div>
                </div>

            </div>

            <div className='mt-5'>


                <div className='container revenuechart_main_div'>
                    <h4>Revenu Status</h4>
                    <ResponsiveContainer style={{ width: '100%', height: '80%' }}>
                        <AreaChart
                            width={800}
                            height={400}
                            data={data}
                            margin={{
                                top: 10,
                                right: 30,
                                left: 0,
                                bottom: 0,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Area type="monotone" dataKey="Revenue" stroke="#8884d8" fill="#8884d8" />
                        </AreaChart>
                    </ResponsiveContainer>
                </div>

                <div className='mt-5'>
                    <PieChart width={400} height={400}>
                        <Tooltip />
                        <Pie
                            data={data2}
                            cx={200}
                            cy={200}
                            innerRadius={60}
                            outerRadius={80}
                            fill="#8884d8"
                            paddingAngle={5}
                            dataKey="value"
                            label={({ name, value }) =>
                                `${name}: ${value}`
                            }
                        >
                            {data2.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                    </PieChart>
                </div>


                <div className='mt-5 container revenuechart_main_div'>

                    <ResponsiveContainer style={{ width: '100%', height: '80%' }}>
                        <BarChart
                            width={800}
                            height={500}
                            data={bardata}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
                            <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
                            <Tooltip />
                            <Legend />
                            <Bar yAxisId="left" dataKey="pv" fill="#8884d8" />
                            <Bar yAxisId="right" dataKey="uv" fill="#82ca9d" />
                        </BarChart>
                    </ResponsiveContainer>

                </div>











            </div>
        </div>
    )
}

export default Dashboard