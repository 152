import React, { useState, useRef } from 'react';
import '../Adminheader/Adminheader.css'
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';
import { pink } from '@mui/material/colors';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom/dist';
import { useStateValue } from '../../../StateProviders';
import { Close } from '@mui/icons-material';
import { Button } from '@mui/material';


import { SwipeableDrawer } from "@mui/material";



function Adminheader() {

    const [{ ShowAdminLoginPop }, dispatch] = useStateValue();

    let navigate = useNavigate()

    const [anchorEl, setAnchorEl] = useState(null);


    // const [AllMsg,setAllMsg] = useState([])
    const [GroupName, setGroupName] = useState('')
    // const [unreadcount,setunreadcount] = useState("")


    const [chatSocket, setChatSocket] = useState(null);
    const [webSocketInitialized, setWebSocketInitialized] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [totalUnseenNotification, setTotalUnseenNotification] = useState(0);

    function getNotifications() {
        var Url = window.API_PREFIX + 'admin/viewnotification'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                {
                    "Token": localStorage.getItem("Token"),

                }
            ),
        }).then(resp => resp.json()).then(data => {
            if (data['status'] === "1") {

                setNotifications(data['notification'])


                const unseenNotification = data['notification'].filter(item => item.IsSeen === false).length;
                if (unseenNotification > 0) {
                    dispatch({ type: "NewNotification" })
                }
                setTotalUnseenNotification(unseenNotification)
            }
        })
    }

    useEffect(() => {
        getNotifications()
    }, [anchorEl])

    useEffect(() => {

        if (!webSocketInitialized) {
            setGroupName('admin_notify')

            const socket = new WebSocket(
                window.WEBSOC + 'ws/AdminNotify/'
            );
            socket.onopen = () => {
                console.log("WebSocket connection opened");
                setWebSocketInitialized(true);

            };
            socket.onmessage = function (e) {
                let received_data_as_object = e['data'];
                // console.log(received_data_as_object)

                if (received_data_as_object.split("_")[0] === "Reload") {
                    getNotifications()
                }
                //   const data = JSON.parse(e.data);
                // setChatLog((prevChatLog) => prevChatLog + e.data + '\n');
            };

            socket.onclose = function (e) {
                console.error('Chat socket closed unexpectedly');
                setWebSocketInitialized(false);

            };
            setChatSocket(socket);
            // return () => {
            //     socket.close();
            // };

        }
    }, [GroupName])

    function handleSeen(notification) {
        var Url = window.API_PREFIX + 'user/get_seen'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                {
                    "Token": localStorage.getItem("Token"),
                    "id": notification.id.toString()
                }
            ),
        }).then(resp => resp.json()).then(data => {
            if (data['status'] === "1") {
                getNotifications();
                if (notification.Type === "Order") {
                    setAnchorEl(null);
                    navigate(`/OrderDetail/${notification.Id_All_Type}`);
                }
            }
        })
    }

    function handleDelete(id) {
        var Url = window.API_PREFIX + 'user/get_delete'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                {
                    "Token": localStorage.getItem("Token"),
                    "id": id.toString()
                }
            ),
        }).then(resp => resp.json()).then(data => {
            if (data['status'] === "1") {

                getNotifications();
            }
        })
    }

    function handleRead() {
        var Url = window.API_PREFIX + 'user/get_allseen'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                {
                    "Token": localStorage.getItem("Token"),
                }
            ),
        }).then(resp => resp.json()).then(data => {
            if (data['status'] === "1") {

                getNotifications();
            }
        })
    }


    function getTimeDifference(timestamp) {
        timestamp = timestamp.replace(/\.$/, '');
        const currentTime = new Date(); // Current date and time
        const previousTime = new Date(timestamp.replace(/-/g, '/')); // Replace '-' with '/' for broader browser compatibility

        const timeDifference = currentTime - previousTime;

        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const months = Math.floor(days / 30); // Approximation, not precise
        const years = Math.floor(months / 12); // Approximation, not precise

        if (seconds < 60) {
            return seconds + ' seconds ago';
        } else if (minutes < 60) {
            return minutes + ' minutes ago';
        } else if (hours < 24) {
            return hours + ' hours ago';
        } else if (days < 30) {
            return days + ' days ago';
        } else if (months < 12) {
            return months + ' months ago';
        } else {
            return years + ' years ago';
        }
    }




    const notificationstyle = (isSeen) => {
        return {
            backgroundColor: isSeen ? 'white' : '#d7d5d5',
            marginTop: "10px",
            borderRadius: "10px"
        }
    }





 

    const [bar, setbar] = useState(false)

    const barstyle = {
        right: bar ? 0 : ""
    }

    const handleMenuClick = () => {
        setbar(!bar);
    };


    const notificationSidebarRef = useRef(null);


    useEffect(() => {
        const handleBodyClick = (event) => {
            if (notificationSidebarRef.current && !notificationSidebarRef.current.contains(event.target)) {
                setbar(false); // Close the sidebar when clicking outside
            }
        };

        window.addEventListener('click', handleBodyClick);

        return () => {
            window.removeEventListener('click', handleBodyClick);
        };
    }, [notificationSidebarRef]);

    const handleBellClick = (event) => {
        event.stopPropagation(); // Stop the event from reaching the window click listener
        handleMenuClick(); // Toggle the notification sidebar
    };




    return (

        <div style={{ width: '100%', }}>

            <div className='notification_sidebar' style={barstyle} ref={notificationSidebarRef} >
                <div className='notificitaion_title_div'>
                    <div className='doctor_header_notification_title'>Notifications</div>
                    <div onClick={handleRead} style={{ borderBottom: '1px solid grey', color: '#483a15' }}>Clear All</div>
                    <div className='icon_div' onClick={() => {
                        setbar(false)
                    }}>
                        <i class="fa-solid fa-xmark" style={{ color: 'black' }} onClick={() => {
                            setbar(false)
                        }}></i>
                    </div>
                </div>
                <div style={{ marginTop: '50px', overflowY: 'scroll', height: '100vh' }}>
                    {notifications.map((notification) => {
                        return (
                            <div style={{ display: "flex", alignItems: "center",margin:'5px' }}>
                                <MenuItem style={notificationstyle(notification.IsSeen)}>
                                    <div style={{ whiteSpace: "pre-wrap" }} onClick={() => handleSeen(notification)}>
                                        <div className='text_notification'>{notification.Title}</div>
                                        <div className='text_notification'>{notification.Message}</div>
                                        <div className='text_notification'>{getTimeDifference(notification.Datetime)}</div>
                                    </div>
                                    <IconButton aria-label="delete" size="small" onClick={() => handleDelete(notification.id)}>
                                        <Close fontSize="small" />
                                    </IconButton>
                                </MenuItem>
                            </div>
                        )
                    })}
                </div>
            </div>



            <div className='Adminheader_main_div'>


                <div className='adminheader_bell_main_div'>

                    {/* <Tooltip title="Notifications">
                        <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-haspopup="true"
                            onClick={toggleDrawer(true)}
                        >
                            <Badge badgeContent={totalUnseenNotification} sx={{ color: pink[500] }} color='primary' >
                                <i style={{ color: "#ffffff" }} class="fa-solid fa-bell "></i>
                            </Badge>

                        </IconButton>
                    </Tooltip> */}






                    {/* <SwipeableDrawer
                        anchor="right"
                        open={open}
                        onClose={toggleDrawer(false)}
                        onOpen={toggleDrawer(true)}
                    >

                        <div style={{ padding: "2px", display: 'flex', justifyContent: 'space-between', position: 'fixed', background: 'white', width: '300px', borderRadius: '10px', zIndex: '999' }}>
                            <div>
                                <div
                                    style={{ backgroundColor: 'white' }}
                                    className='doctor_header_notification_title'>Notifications</div>
                            </div>
                            <div className='notification_button'>
                                <Button style={{ background: 'white', color: '#483a15', border: '1px solid black', borderRadius: '10px' }} onClick={handleRead}>Clear All</Button>
                            </div>
                        </div>



                        <div style={{ paddingTop: "50px" }}>
                            {notifications.map((notification) => {
                                return (
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <MenuItem style={notificationstyle(notification.IsSeen)}>
                                            <div style={{ whiteSpace: "pre-wrap" }} onClick={() => handleSeen(notification)}>
                                                <div>{notification.Title}</div>
                                                <div>{notification.Message}</div>
                                                <div>{getTimeDifference(notification.Datetime)}</div>
                                            </div>
                                            <IconButton aria-label="delete" size="small" onClick={() => handleDelete(notification.id)}>
                                                <Close fontSize="small" />
                                            </IconButton>
                                        </MenuItem>
                                    </div>
                                )
                            })}
                        </div>


                    </SwipeableDrawer> */}


                    <div>


                        <IconButton

                            aria-label="more"
                            id="long-button"
                            aria-haspopup="true"
                            onClick={handleBellClick}
                        >
                            <Badge badgeContent={totalUnseenNotification} sx={{ color: pink[500] }} color='primary' >
                                <i style={{ color: "#ffffff" }} class="fa-solid fa-bell "></i>
                            </Badge>

                        </IconButton>


                    </div>


                    <div className='d-flex align-items-center' style={{ color: 'white', cursor: 'pointer' }}>
                        <i class="fa-solid fa-arrow-right-from-bracket px-2 fa-xl"
                            onClick={(e) => {
                                localStorage.clear()
                                navigate("/");
                                e.preventDefault();
                                dispatch({ type: "OpenALogin" })

                            }}></i>
                    </div>



                </div>


            </div>
        </div>
    )
}

export default Adminheader