import "./AdminLogin.css";
import { useState } from "react";
import { useStateValue } from "../../../StateProviders";
import mainlogo from '../../../../images/kandariwalamainlogo.png';
import { useNavigate } from "react-router-dom";

function AdminLogin() {
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [{ ShowAdminLoginPop }, dispatch] = useStateValue();

  const [Inputtype, setInputtype] = useState("password");
  const [ButtonName, setButtonName] = useState(<i class="fa-solid fa-eye"></i>);

  let navigate = useNavigate();

  return (
    < div className="d-flex">
      <div className="admin_login_left">

        <div className="d-flex justify-content-start" style={{ position: 'absolute' }} >
          <img alt="logo" style={{ width: '40%', borderRadius: '10px' }} src={mainlogo}></img>
        </div>

        <div style={{ verticalAlign: 'bottom', display: 'table-cell' }} >
          <div className="d-flex admin_policy_div">
            <div className="me-5" onClick={() => {
              navigate('/terms-and-conditions')
            }}>Terms and Conditions</div>
            <div onClick={() => {
              navigate('/shipping-policy')
            }}>Shipping Policy</div>
          </div>
          <div className="d-flex mt-1 admin_policy_div">
            <div className="me-5" onClick={() => {
              navigate('/return-and-refund-policy')
            }}>Return and Refund Policy</div>
            <div onClick={() => {
              navigate("/privacy-policy")
            }}>Privacy Policy</div>
          </div>
          <div className="mt-2" style={{ fontSize: '16px' }}>* Kandariwala Kaka is Managed by Kandariwala Traders.</div>
        </div>

      </div>

      <div className=" adminlogin_main_sub_division">
        <div className="adminlogin_sub_main_division col-md-7 col-lg-4">
          <div className="adminlogin_login_title_name">Welcome To,<br/> Kandariwalakaka</div>
          <div className="mt-3">
            <div className="adminlogin_label_name">Email</div>
            <div>
              <input
                type="text"
                class="form-control adminlogin_text_search_field"
                id="inputtext"
                placeholder="Email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="mt-3">
            <div className="adminlogin_label_name">Password</div>
            <div className="admin_login_password_field_division">
              <div className="admin_login_email_field_division1">
                <input
                  type={Inputtype}
                  class="form-control adminlogin_text_password_field"
                  id="inputPassword"
                  placeholder="Password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}

                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      var Url = window.API_PREFIX + "admin/AdminLogin";
                      fetch(Url, {
                        method: "POST",
                        headers: {
                          "Content-type": "application/json",
                        },
                        body: JSON.stringify({ Email: Email, Password: Password }),
                      })
                        .then((resp) => resp.json())
                        .then((data) => {
                          console.log(data);
                          if (data["status"] == "1") {
                            localStorage.setItem("Token", data["Token"]);
                            dispatch({
                              type: "CloseALogin",
                            });
                            navigate('/allorder')
                          } else {
                            alert("Invalid ID/Password");
                          }
                        });
                    }
                  }
                  }
                />
              </div>
              <div className="admin_login_password_eyes_icon">
                <i
                  className=" eye_icon"
                  onClick={(e) => {
                    if (Inputtype == "password") {
                      setInputtype("text");
                      setButtonName(<i class="fa-solid fa-eye-slash"></i>);
                    } else {
                      setInputtype("password");
                      setButtonName(<i class="fa-solid fa-eye"></i>);
                    }

                    e.preventDefault();
                  }}
                >
                  {ButtonName}
                </i>
              </div>
            </div>
          </div>

          <div className="adminlogin_login_btn_division">
            <button
              className="adminlogin_login_btn"
              onClick={() => {
                var Url = window.API_PREFIX + "admin/AdminLogin";
                fetch(Url, {
                  method: "POST",
                  headers: {
                    "Content-type": "application/json",
                  },
                  body: JSON.stringify({ Email: Email, Password: Password }),
                })
                  .then((resp) => resp.json())
                  .then((data) => {
                    console.log(data);
                    if (data["status"] == "1") {
                      localStorage.setItem("Token", data["Token"]);
                      dispatch({
                        type: "CloseALogin",
                      });
                      navigate('/allorder')
                    } else {
                      alert("Invalid ID/Password");
                    }
                  });
              }}
            >
              Login
            </button>




          </div>





        </div>
      </div>

    </div>
  );
}

export default AdminLogin;
